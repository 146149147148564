<template>
  <div class="main-content-wrap sidenav-open d-flex flex-column">
    <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pl-3 pr-1' : 'pl-4'"
      class="main-header" style="z-index: 60;">
      <div class="row">
        <div class="logo col-md-4 col-lg-4 col-12 py-0 px-0"
          v-if="userType != 1 && check_all_services('Attendance') && !authSubscriptionExpired">
          <button @click.prevent="checkOutLoading ? null : check_in()" v-if="checkedIn" type="button"
            style="background-color: #c70039 !important;"
            class="btn btn-rounded btn-raised ripple btn-raised-primary m-1 ml-3 text-white">
            {{ checkOutText }}
            <v-icon :style="!checkOutLoading
        ? 'display: none;'
        : 'color: #fff; padding: 0px 35px; font-size: 14px;'
      ">
              fas fa-circle-notch fa-spin
            </v-icon>
            <span v-if="!checkOutLoading" class="ul-btn__icon"><i class="i-Clock-Forward"></i></span>
          </button>
          <button @click.prevent="checkInLoading ? null : check_in()" v-else type="button"
            class="btn btn-rounded btn-raised ripple btn-raised-secondary m-1 ml-3 text-white">
            {{ checkInText }}
            <v-icon :style="!checkInLoading
        ? 'display: none;'
        : 'color: #fff; padding: 0px 30px; font-size: 14px;'
      ">
              fas fa-circle-notch fa-spin
            </v-icon>
            <span v-if="!checkInLoading" class="ul-btn__icon"><i class="i-Clock-Forward"></i></span>
          </button>
          <span class="ml-2" style="top: 4%; position: relative;">{{ timeSpent }} hrs</span>
        </div>
      </div>
      <div style="margin: auto"></div>
      <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-0' : 'pr-4'"
        class="header-part-right">
        <div class="dropdown">
          <button class="btn ripple m-1" type="button" @click="refresh_page()"
            :class="refreshing_page ? 'rotate-spinner' : ''">
            <span class="ul-btn__icon"><i style="font-weight: bold;" class="i-Repeat-21"></i></span>
          </button>
        </div>
        <!-- Grid menu Dropdown -->
        <div class="dropdown">
          <button v-if="userType == 1 && check_all_services('Absence or Leave')" type="button"
            class="btn btn-outline-secondary m-1" @click="$router.push({ name: 'Absence or Leave' })">
            All Leave
          </button>
          <button v-if="(userType != 1 && check_all_services('Absence or Leave'))
      &&
      ($vuetify.breakpoint.name != 'xs')" type="button" class="btn btn-outline-secondary m-1"
            @click="$router.push({ name: 'LeaveApply' })">
            Leave History
          </button>
        </div>
        <!-- Notificaiton -->
        <div class="dropdown">
          <button v-if="userType == 1 && check_all_services('Attendance')" type="button"
            class="btn btn-outline-secondary m-1" @click="$router.push({ name: 'Attendance' })">
            Log History
          </button>
          <button v-if="userType != 1 && check_all_services('Attendance')" type="button"
            class="btn btn-outline-secondary m-1" @click="$router.push({ name: 'LogReport' })">
            Log Time
          </button>
        </div>
        <!-- Notificaiton End -->
        <!-- User avatar dropdown -->
      </div>
    </div>
    <!-- ======== Body content start ========= -->
    <div class="main-content"
      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
      <div class="row" v-if="authCompanyServices.length === 2">
        <!-- ICON BG-->
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
            <div class="card-header">Birthdays</div>
            <div v-if="birthday_employees.length >= 1"
              :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null"
              class="card-body text-center" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
              <div :class="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'pt-2' : null"
                class="ul-widget5 ul-widget4__item ul-widget4__users" v-for="employee in birthday_employees"
                :key="employee.id">
                <div class="ul-widget-s5__item mb-0 row w-100 mx-auto mb-0">
                  <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'my-0' : null"
                    class="ul-widget-s5__content col-9 pb-0">
                    <div class="ul-widget-s5__pic pr-0">
                      <img id="userDropdown" :src="employee.profile_image_url
        ? ASSETS_URL + employee.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                    </div>
                    <div class="ul-widget3-info text-left">
                      <a class="__g-widget-username" style="cursor: pointer;">
                        <span v-if="userType == 1" @click="
      $router.push({
        name: 'ViewEmployee',
        params: { id: employee.id }
      })
      " class="t-font-bolder">{{
      employee.employee_fristname +
      " " +
      employee.employee_lastname
    }}</span>
                        <span v-if="userType != 1" class="t-font-bolder">{{
      employee.employee_fristname +
      " " +
      employee.employee_lastname
    }}</span>
                      </a>
                      <br />
                      <span v-if="new Date(employee.employee_date_of_birth)
        .toDateString()
        .substring(4, 10) == new Date().toDateString().substring(4, 10)"
                        class="ul-widget-notification-item-time">{{
      employee.user_id == authUserData.id ? 'Happy birthday!' : 'Call on ' + employee.employee_phone1
    }}
                      </span>
                      <span v-else class="ul-widget-notification-item-time">{{
        employee.department_id
          ? get_dept_name(employee.department_id)
          : "No department"
      }}
                      </span>
                    </div>
                  </div>
                  <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'my-0' : null"
                    class="ul-widget-s5__content col-3 pb-0" style="float: right;">
                    <div class="ul-widget-s5__progress pr-0">
                      <h4>
                        {{
      new Date(employee.employee_date_of_birth)
        .toDateString()
        .substring(4, 10) == new Date().toDateString().substring(4, 10)
        ? (employee.user_id == authUserData.id ? '🍾🥳🎈🍰' : 'Today')
        : new Date(employee.employee_date_of_birth + ' 00:00:00')
          .toDateString()
          .substring(4, 10)
    }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No Birthdays for this month.</p>
            </div>
          </div>
        </div>
        <template v-if="userType == 1">
          <div v-if="authCompanyCandidates2.length >= 1" class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header" @click="$router.push({ name: 'Onboarding' })" style="cursor: pointer;">
                Candidates
              </div>
              <div class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
                <div class="ul-widget__body" style="
                    ">
                  <div class="tab-content py-2">
                    <div class="tab-pane active show" id="__g-widget4-tab1-content">
                      <div class="ul-widget1">
                        <div class="ul-widget4__item ul-widget4__users" v-for="candidate in authCompanyCandidates2"
                          :key="candidate.id">
                          <div class="ul-widget4__img">
                            <img id="userDropdown" :src="candidate.profile_image_url
        ? ASSETS_URL + candidate.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                          </div>
                          <div class="ul-widget2__info ul-widget4__users-info">
                            <a class="ul-widget2__title" @click="
      $router.push({
        name: 'ViewCandidate',
        params: { id: candidate.id }
      })
      ">{{ candidate.employee_fristname }}
                              {{ candidate.employee_lastname }}</a><span class="ul-widget2__username" href="#">No job
                              role</span>
                          </div>
                          <div class="ul-widget4__actions">
                            <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <span class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span>
                            </button>
                            <div class="dropdown-menu" x-placement="top-start"
                              style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                              <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;" @click="
      $router.push({
        name: 'ViewCandidate',
        params: { id: candidate.id }
      })
      "><i class="fas fa-eye" style="color: inherit !important;">
                                </i>
                                View</a><a class="dropdown-item ul-widget__link--font" style="cursor: pointer;" @click="
      $router.push({
        name: 'EditCandidate',
        params: { id: candidate.id }
      })
      "><i class="fas fa-pencil-alt"> </i> Edit</a><a
                                class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                                @click="delete_inline(candidate.id, 1)"><i class="fas fa-trash-alt"></i> Delete</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header">Candidates</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No candidates found.</p>
              </div>
            </div>
          </div>
        </template>
        <template v-if="userType == 1">
          <div v-if="true_employees.length >= 1" class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EFCEAD80;">
              <div class="card-header" @click="
      $router.push({
        name: 'Onboarding',
        query: { view: 'employee' }
      })
      " style="cursor: pointer;">
                Employees
              </div>
              <div class="card-body" style="
                        height: 264px;
                        overflow-y: scroll;
                        padding-top: 0;
                        padding-bottom: 0;
                        ">
                <div class="ul-widget__body" style="
                        ">
                  <div class="tab-content py-2">
                    <div class="tab-pane active show" id="__g-widget4-tab1-content">
                      <div class="ul-widget1">
                        <template v-for="person in true_employees">
                          <div :key="person.id" v-if="person.user_type != 1" class="ul-widget4__item ul-widget4__users">
                            <div class="ul-widget4__img">
                              <img id="userDropdown" :src="person.profile_image_url
        ? ASSETS_URL + person.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            </div>
                            <div class="ul-widget2__info ul-widget4__users-info">
                              <a class="ul-widget2__title" @click="
      $router.push({
        name: 'ViewEmployee',
        params: { id: person.id }
      })
      ">{{ person.employee_fristname }}
                                {{ person.employee_lastname ? person.employee_lastname : '' }}</a><span
                                class="ul-widget2__username">{{
      get_dept_name(person.department_id)
    }}</span>
                            </div>
                            <div class="ul-widget4__actions">
                              <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span class="_dot _inline-dot bg-primary"></span><span
                                  class="_dot _inline-dot bg-primary"></span><span
                                  class="_dot _inline-dot bg-primary"></span>
                              </button>
                              <div class="dropdown-menu" x-placement="top-start"
                                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                <!-- <a
                              class="dropdown-item ul-widget__link--font"
                              style="cursor: pointer;"
                               @click="$router.push({ name: 'ViewCandidate', params: { id: person.id } })"
                              ><i class="fas fa-eye" style="color: inherit !important;"> </i> View</a
                            ><a
                              class="dropdown-item ul-widget__link--font"
                              style="cursor: pointer;"
                              @click="$router.push({ name: 'EditCandidate', params: { id: person.id } })"
                              ><i class="fas fa-pencil-alt"> </i> Edit</a
                            > -->
                                <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;" @click="
    $router.push({
      name: 'ViewEmployee',
      params: { id: person.id }
    })
      "><i class="fas fa-eye" style="color: inherit !important;">
                                  </i>
                                  View</a><a class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                                  @click="
      $router.push({
        name: 'EditEmployee',
        params: { id: person.id }
      })
      "><i class="fas fa-pencil-alt"> </i> Edit</a><a
                                  class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                                  @click="delete_inline(person.id, 2)"><i class="fas fa-trash-alt"></i> Delete</a>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EFCEAD80;">
              <div class="card-header">Employees</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No employees found.</p>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="row" v-else>
        <!-- ICON BG-->
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
            <div class="card-header">Birthdays</div>
            <div v-if="birthday_employees.length >= 1"
              :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null"
              class="card-body text-center" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
              <div :class="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'pt-2' : null"
                class="ul-widget5 ul-widget4__item ul-widget4__users" v-for="employee in birthday_employees"
                :key="employee.id">
                <div class="ul-widget-s5__item mb-0 row w-100 mx-auto mb-0">
                  <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'my-0' : null"
                    class="ul-widget-s5__content col-9 pb-0">
                    <div class="ul-widget-s5__pic pr-0">
                      <img id="userDropdown" :src="employee.profile_image_url
        ? ASSETS_URL + employee.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                    </div>
                    <div class="ul-widget3-info text-left">
                      <a class="__g-widget-username" style="cursor: pointer;">
                        <span v-if="userType == 1" @click="
      $router.push({
        name: 'ViewEmployee',
        params: { id: employee.id }
      })
      " class="t-font-bolder">{{
      employee.employee_fristname +
      " " +
      employee.employee_lastname
    }}</span>
                        <span v-if="userType != 1" class="t-font-bolder">{{
      employee.employee_fristname +
      " " +
      employee.employee_lastname
    }}</span>
                      </a>
                      <br />
                      <span v-if="new Date(employee.employee_date_of_birth)
        .toDateString()
        .substring(4, 10) == new Date().toDateString().substring(4, 10)"
                        class="ul-widget-notification-item-time">{{
      employee.user_id == authUserData.id ? 'Happy birthday!' : 'Call on ' + employee.employee_phone1
    }}
                      </span>
                      <span v-else class="ul-widget-notification-item-time">{{
        employee.department_id
          ? get_dept_name(employee.department_id)
          : "No department"
      }}
                      </span>
                    </div>
                  </div>
                  <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'my-0' : null"
                    class="ul-widget-s5__content col-3 pb-0" style="float: right;">
                    <div class="ul-widget-s5__progress pr-0">
                      <h4>
                        {{
      new Date(employee.employee_date_of_birth)
        .toDateString()
        .substring(4, 10) == new Date().toDateString().substring(4, 10)
        ? (employee.user_id == authUserData.id ? '🍾🥳🎈🍰' : 'Today')
        : new Date(employee.employee_date_of_birth + ' 00:00:00')
          .toDateString()
          .substring(4, 10)
    }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No Birthdays for this month.</p>
            </div>
          </div>
        </div>
        <template v-if="userType == 1">
          <div v-if="authCompanyCandidates2.length >= 1" class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header" @click="$router.push({ name: 'Onboarding' })" style="cursor: pointer;">
                Candidates
              </div>
              <div class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
                <div class="ul-widget__body" style="
                    ">
                  <div class="tab-content py-2">
                    <div class="tab-pane active show" id="__g-widget4-tab1-content">
                      <div class="ul-widget1">
                        <div class="ul-widget4__item ul-widget4__users" v-for="candidate in authCompanyCandidates2"
                          :key="candidate.id">
                          <div class="ul-widget4__img">
                            <img id="userDropdown" :src="candidate.profile_image_url
        ? ASSETS_URL + candidate.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                          </div>
                          <div class="ul-widget2__info ul-widget4__users-info">
                            <a class="ul-widget2__title" @click="
      $router.push({
        name: 'ViewCandidate',
        params: { id: candidate.id }
      })
      ">{{ candidate.employee_fristname }}
                              {{ candidate.employee_lastname }}</a><span class="ul-widget2__username" href="#">No job
                              role</span>
                          </div>
                          <div class="ul-widget4__actions">
                            <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <span class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span>
                            </button>
                            <div class="dropdown-menu" x-placement="top-start"
                              style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                              <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;" @click="
      $router.push({
        name: 'ViewCandidate',
        params: { id: candidate.id }
      })
      "><i class="fas fa-eye" style="color: inherit !important;">
                                </i>
                                View</a><a class="dropdown-item ul-widget__link--font" style="cursor: pointer;" @click="
      $router.push({
        name: 'EditCandidate',
        params: { id: candidate.id }
      })
      "><i class="fas fa-pencil-alt"> </i> Edit</a><a
                                class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                                @click="delete_inline(candidate.id, 1)"><i class="fas fa-trash-alt"></i> Delete</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header">Candidates</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No candidates found.</p>
              </div>
            </div>
          </div>
        </template>
        <template v-if="userType == 1">
          <div v-if="true_employees.length >= 1" class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EFCEAD80;">
              <div class="card-header" @click="
      $router.push({
        name: 'Onboarding',
        query: { view: 'employee' }
      })
      " style="cursor: pointer;">
                Employees
              </div>
              <div class="card-body" style="
                        height: 264px;
                        overflow-y: scroll;
                        padding-top: 0;
                        padding-bottom: 0;
                        ">
                <div class="ul-widget__body" style="
                        ">
                  <div class="tab-content py-2">
                    <div class="tab-pane active show" id="__g-widget4-tab1-content">
                      <div class="ul-widget1">
                        <template v-for="person in true_employees">
                          <div :key="person.id" v-if="person.user_type != 1" class="ul-widget4__item ul-widget4__users">
                            <div class="ul-widget4__img">
                              <img id="userDropdown" :src="person.profile_image_url
        ? ASSETS_URL + person.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            </div>
                            <div class="ul-widget2__info ul-widget4__users-info">
                              <a class="ul-widget2__title" @click="
      $router.push({
        name: 'ViewEmployee',
        params: { id: person.id }
      })
      ">{{ person.employee_fristname }}
                                {{ person.employee_lastname ? person.employee_lastname : '' }}</a><span
                                class="ul-widget2__username">{{
      get_dept_name(person.department_id)
    }}</span>
                            </div>
                            <div class="ul-widget4__actions">
                              <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span class="_dot _inline-dot bg-primary"></span><span
                                  class="_dot _inline-dot bg-primary"></span><span
                                  class="_dot _inline-dot bg-primary"></span>
                              </button>
                              <div class="dropdown-menu" x-placement="top-start"
                                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                <!-- <a
                              class="dropdown-item ul-widget__link--font"
                              style="cursor: pointer;"
                               @click="$router.push({ name: 'ViewCandidate', params: { id: person.id } })"
                              ><i class="fas fa-eye" style="color: inherit !important;"> </i> View</a
                            ><a
                              class="dropdown-item ul-widget__link--font"
                              style="cursor: pointer;"
                              @click="$router.push({ name: 'EditCandidate', params: { id: person.id } })"
                              ><i class="fas fa-pencil-alt"> </i> Edit</a
                            > -->
                                <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;" @click="
    $router.push({
      name: 'ViewEmployee',
      params: { id: person.id }
    })
      "><i class="fas fa-eye" style="color: inherit !important;">
                                  </i>
                                  View</a><a class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                                  @click="
      $router.push({
        name: 'EditEmployee',
        params: { id: person.id }
      })
      "><i class="fas fa-pencil-alt"> </i> Edit</a><a
                                  class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                                  @click="delete_inline(person.id, 2)"><i class="fas fa-trash-alt"></i> Delete</a>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EFCEAD80;">
              <div class="card-header">Employees</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No employees found.</p>
              </div>
            </div>
          </div>
        </template>

        <template v-if="check_all_services('Dockets')">
          <div v-if="authCompanyDockets.length >= 1" class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header">Dockets</div>
              <div class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
                <div class="ul-widget__body">
                  <div class="tab-content py-2 pl-0">
                    <div class="tab-pane active show" id="__g-widget4-tab1-content">
                      <div class="ul-widget1">
                        <div class="ul-widget4__item ul-widget4__users" v-for="docket in authCompanyDockets"
                          :key="docket.id">
                          <div class="ul-widget2__info ul-widget4__users-info ml-0">
                            <a class="ul-widget2__title" @click="
      $router.push({
        name: 'Dockets'
      })
      ">{{ docket.case }}</a>
                            <span class="ul-widget2__username" href="#">
                              {{ get_docket_date(docket.date) }}
                            </span>
                          </div>
                          <div class="ul-widget4__actions">
                            <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <span class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span>
                            </button>
                            <div class="dropdown-menu" x-placement="top-start"
                              style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                              <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;" @click="
      $router.push({
        name: 'Dockets'
      })
      "><i class="fas fa-eye" style="color: inherit !important;">
                                </i>
                                View</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header">Dockets</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No dockets found.</p>
              </div>
            </div>
          </div>
        </template>

        <div
          v-if="(userType == 1 || (userType != 1 && permissionIsAssigned('requisition-read'))) && check_all_services('Requisitions')"
          class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EFCEAD80;">
            <div class="card-header"
              @click="filtered_requisitions.length >= 1 ? $router.push({ name: 'Requisitions' }) : null"
              :style="filtered_requisitions.length >= 1 ? 'cursor: pointer;' : null">Requisition requests</div>
            <div v-if="filtered_requisitions.length == 0" class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No requisitions request.</p>
            </div>
            <div v-else class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
              <div class="ul-widget__body" style="
                    ">
                <div class="tab-content py-2">
                  <div class="tab-pane active show" id="__g-widget4-tab1-content">
                    <div class="ul-widget1">
                      <div class="ul-widget4__item ul-widget4__users" v-for="requisition in filtered_requisitions"
                        :key="requisition.requisition.id">
                        <div class="ul-widget4__img">
                          <img id="userDropdown" :src="get_profile_image(requisition.requisition.employee_id)
        ? ASSETS_URL + get_profile_image(requisition.requisition.employee_id)
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        </div>
                        <div class="ul-widget2__info ul-widget4__users-info">
                          <a class="ul-widget2__title" href="#">{{
      requisition.requisition.title
    }}&nbsp;(₦{{ (requisition.requisition.amount || 0) | number_with_commas }})</a><span
                            class="ul-widget2__username" href="#">{{
      getRequisitionTypeName(requisition.requisition.request_type)
    }}&nbsp;({{ new Date(requisition.requisition.created_at).toUTCString().substring(0, 16)
                            }})</span>
                        </div>
                        <div v-if="userType == 1 || permissionIsAssigned('requisition-approve')"
                          class="ul-widget4__actions">
                          <button class="btn _r_btn border-0" type="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <span class="_dot _inline-dot bg-primary"></span><span
                              class="_dot _inline-dot bg-primary"></span><span
                              class="_dot _inline-dot bg-primary"></span>
                          </button>
                          <div class="dropdown-menu" x-placement="top-start"
                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                            <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                              @click="approve_requisition(requisition)"><i class="fas fa-check"
                                style="color: inherit !important;">
                              </i>
                              Approve</a><a class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                              @click="disapprove_requisition(requisition)"><i class="fas fa-times"> </i> Disapprove</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="userType != 1 && check_all_services('Attendance') && !authSubscriptionExpired"
          class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
            <div class="card-header">Attendance</div>
            <div class="card-body" style="height: 264px; padding-top: 0px; padding-bottom: 0px;">
              <div class="row mt-5">
                <div class="col-lg-8 col-md-8 col-8">
                  <input class="form-control" type="text" v-model="formData.checkInNote" placeholder="Add Notes" />
                </div>
                <div class="col-lg-4 col-md-4 col-4 pl-0">
                  <button @click.prevent="checkOutLoading ? null : check_in()" v-if="checkedIn" type="button"
                    style="background-color: #c70039 !important;"
                    class="btn btn-raised ripple btn-raised-primary m-1 mt-0 text-white">
                    {{ checkOutText }}
                    <v-icon :style="!checkOutLoading
        ? 'display: none;'
        : 'color: #fff; padding: 0px 35px; font-size: 14px;'
      ">
                      fas fa-circle-notch fa-spin
                    </v-icon>
                    <span v-if="!checkOutLoading" class="ul-btn__icon"><i class="i-Clock-Forward"></i></span>
                  </button>
                  <button @click.prevent="checkInLoading ? null : check_in()" v-else type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 mt-0 text-white">
                    {{ checkInText }}
                    <v-icon :style="!checkInLoading
        ? 'display: none;'
        : 'color: #fff; padding: 0px 30px; font-size: 14px;'
      ">
                      fas fa-circle-notch fa-spin
                    </v-icon>
                    <span v-if="!checkInLoading" class="ul-btn__icon"><i class="i-Clock-Forward"></i></span>
                  </button>
                </div>
                <div class="col-lg-12 col-md-12 col sm-12 text-center">
                  <h2 style="
                                font-size: 300%;
                                font-weight: bold;
                            " class="mt-3">
                    {{ timeSpent }} hrs
                  </h2>
                  <p>
                    {{
      `${Date().split(" ")[2]} ${Date().split(" ")[1]} ${Date().split(" ")[3]
      }`
    }}
                  </p>
                  <span v-if="!checkedIn">Yet to Check-in</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="check_all_services('Attendance')" class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
            <div class="card-header">Top 5 punctual</div>
            <div v-if="topEarliestEmployees.length" class="card-body" style="
                        height: 264px;
                        overflow-y: scroll;
                        padding-top: 0;
                        padding-bottom: 0;
                        ">
              <div class="ul-widget__body" style="
                        ">
                <div class="tab-content py-2">
                  <div class="tab-pane active show" id="__g-widget4-tab1-content">
                    <div class="ul-widget1">
                      <div v-for="(e, i) in topEarliestEmployees" :key="e.id" class="ul-widget4__item ul-widget4__users"
                        :class="i >= 5 ? 'd-none' : null">
                        <div class="ul-widget4__img">
                          <img id="userDropdown" :src="get_profile_image(e.id) &&
        !!authCompanyEmployees
        ? ASSETS_URL +
        get_profile_image(e.id)
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        </div>
                        <div class="ul-widget2__info ul-widget4__users-info">
                          <a class="ul-widget2__title" href="#">{{ e.employee_fristname }}
                            {{ e.employee_lastname }}</a><span class="ul-widget2__username" href="#">{{
      get_dept_name(e.department_id)
    }}</span>
                        </div>
                        <div class="ul-widget4__actions w-75">
                          <span v-if="!e.avgIndexTrackerValue">
                            <i>&nbsp;No checkin this month</i>
                          </span>
                          <div class="dropdown-menu" x-placement="top-start"
                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No Data Available.</p>
            </div>
          </div>
        </div>
        <div v-if="check_all_services('Attendance')" class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
            <div class="card-header">Top 5 tardy</div>
            <div v-if="topTardyEmployees.length" class="card-body" style="
                        height: 264px;
                        overflow-y: scroll;
                        padding-top: 0;
                        padding-bottom: 0;
                        ">
              <div class="ul-widget__body" style="
                        ">
                <div class="tab-content py-2">
                  <div class="tab-pane active show" id="__g-widget4-tab1-content">
                    <div class="ul-widget1">
                      <div v-for="(e, i) in topTardyEmployees" :key="e.id" class="ul-widget4__item ul-widget4__users"
                        :class="i >= 5 ? 'd-none' : null">
                        <div class="ul-widget4__img">
                          <img id="userDropdown" :src="get_profile_image(e.id) &&
        !!authCompanyEmployees
        ? ASSETS_URL +
        get_profile_image(e.id)
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        </div>
                        <div class="ul-widget2__info ul-widget4__users-info">
                          <a class="ul-widget2__title" href="#">{{ e.employee_fristname }}
                            {{ e.employee_lastname }}</a><span class="ul-widget2__username" href="#">{{
      get_dept_name(e.department_id)
    }}</span>
                        </div>
                        <div class="ul-widget4__actions w-75">
                          <span v-if="!e.avgIndexTrackerValue">
                            <i>&nbsp;No checkin this month</i>
                          </span>
                          <div class="dropdown-menu" x-placement="top-start"
                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No Data Available.</p>
            </div>
          </div>
        </div>

        <div v-if="check_all_services('Absence or Leave')" class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EFCEAD80;">
            <div class="card-header"
              @click="pending_leave.length >= 1 ? $router.push({ name: 'Absence or Leave' }) : null"
              :style="pending_leave.length >= 1 ? 'cursor: pointer;' : null">Pending leave requests</div>
            <div v-if="pending_leave.length == 0" class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No pending leave request.</p>
            </div>
            <div v-else class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
              <div class="ul-widget__body" style="
                    ">
                <div class="tab-content py-2">
                  <div class="tab-pane active show" id="__g-widget4-tab1-content">
                    <div class="ul-widget1">
                      <div class="ul-widget4__item ul-widget4__users" v-for="leave in pending_leave" :key="leave.id">
                        <div class="ul-widget4__img">
                          <img id="userDropdown" :src="leave.profile_image_url
        ? ASSETS_URL + leave.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        </div>
                        <div class="ul-widget2__info ul-widget4__users-info">
                          <a class="ul-widget2__title" href="#">{{
      leave.employee_name
    }}</a><span class="ul-widget2__username" href="#">{{
        get_type_name(leave.leave_type)
      }}</span>
                        </div>
                        <div v-if="userType == 1" class="ul-widget4__actions">
                          <button class="btn _r_btn border-0" type="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <span class="_dot _inline-dot bg-primary"></span><span
                              class="_dot _inline-dot bg-primary"></span><span
                              class="_dot _inline-dot bg-primary"></span>
                          </button>
                          <div class="dropdown-menu" x-placement="top-start"
                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                            <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                              @click="$router.push({ name: 'Absence or Leave' })"><i class="fas fa-eye"> </i> View</a>
                            <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                              @click="approve_single_leave(leave)"><i class="fas fa-check"
                                style="color: inherit !important;">
                              </i>
                              Approve</a><a class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                              @click="disapprove_single_leave(leave)"><i class="fas fa-times"> </i> Disapprove</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="userType == 1">
          <div v-if="authCompanyDepartments.length >= 1" class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header">Departments</div>
              <div v-if="authCompanyDepartments.length == 0" class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">
                  No department found.
                </p>
              </div>
              <div v-else class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
                <div class="ul-widget__body">
                  <div v-for="dept in authCompanyDepartments" :key="dept.id" class="__g-widget4">
                    <div class="ul-widget4__item">
                      <div class="ul-widget4__pic-icon">
                        <i class="fad fa-users text-info"></i>
                      </div>
                      <router-link class="ul-widget4__title" :to="{ name: 'Department', params: { id: dept.id } }">{{
      dept.department_name
    }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header">Departments</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No departments found.</p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="(employeeDepartments.length >= 1 || otherDepartments.length >= 1)"
            class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
              <div class="card-header">Departments</div>
              <div v-if="employeeDepartments.length == 0 || otherDepartments.length == 0" class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">
                  No department found.
                </p>
              </div>
              <div v-else class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
                <div class="ul-widget__body">
                  <div v-for="(dept, index) in employeeDepartments" :key="'employee-department-' + index"
                    class="__g-widget4">
                    <div class="ul-widget4__item">
                      <div class="ul-widget4__pic-icon">
                        <i class="fad fa-users text-info"></i>
                      </div>
                      <router-link class="ul-widget4__title mr-1" :to="{ name: 'Department', params: { id: dept.id } }">
                        {{
      get_dept_name(dept.id)
    }} </router-link> ({{ dept.primary_member ? 'Primary member' : 'Secondary member' }})
                    </div>
                  </div>
                  <div v-for="(dept, index) in otherDepartments" :key="'other-department-' + index" class="__g-widget4">
                    <div class="ul-widget4__item">
                      <div class="ul-widget4__pic-icon">
                        <i class="fad fa-users text-info"></i>
                      </div>
                      <router-link class="ul-widget4__title mr-1" :to="{ name: 'Department', params: { id: dept.id } }">
                        {{
      get_dept_name(dept.id)
    }} </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
              <div class="card-header">Departments</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No departments found.</p>
              </div>
            </div>
          </div>
        </template>
        <template v-if="userType == 1">
          <div v-if="authCompanyCommittees.length >= 1" class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
              <div class="card-header">Committees</div>
              <div v-if="authCompanyCommittees.length == 0" class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">
                  No committee found.
                </p>
              </div>
              <div v-else class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
                <div class="ul-widget__body">
                  <div v-for="committee in authCompanyCommittees" :key="'committee-' + committee.id"
                    class="__g-widget4">
                    <div class="ul-widget4__item">
                      <div class="ul-widget4__pic-icon">
                        <i class="fad fa-users text-info"></i>
                      </div>
                      <router-link class="ul-widget4__title"
                        :to="{ name: 'Committee', params: { department_id: committee.department_id, id: committee.id } }">
                        {{
      committee.committee_name
    }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
              <div class="card-header">Committees</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No committees found.</p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="(employeeCommittees.length >= 1 || otherCommittees.length >= 1)"
            class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
              <div class="card-header">Committees</div>
              <div v-if="(employeeCommittees.length == 0 || otherCommittees.length == 0)" class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">
                  No committee found.
                </p>
              </div>
              <div v-else class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
                <div class="ul-widget__body">
                  <div v-for="committee in employeeCommittees" :key="'committee-id-' + committee.id"
                    class="__g-widget4">
                    <div class="ul-widget4__item">
                      <div class="ul-widget4__pic-icon">
                        <i class="fad fa-users text-info"></i>
                      </div>
                      <router-link class="ul-widget4__title mr-1"
                        :to="{ name: 'Committee', params: { department_id: committee.department_id, id: committee.id } }">
                        {{
      get_committee(committee.id) ? get_committee(committee.id).committee_name : 'Committee not found'
    }}</router-link> (Member)
                    </div>
                  </div>
                  <div v-for="committee in otherCommittees" :key="'committee-id-' + committee.id" class="__g-widget4">
                    <div class="ul-widget4__item">
                      <div class="ul-widget4__pic-icon">
                        <i class="fad fa-users text-info"></i>
                      </div>
                      <router-link class="ul-widget4__title"
                        :to="{ name: 'Committee', params: { department_id: committee.department_id, id: committee.id } }">
                        {{
      get_committee(committee.id) ? get_committee(committee.id).committee_name : 'Committee not found'
    }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
              <div class="card-header">Committees</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No committees found.</p>
              </div>
            </div>
          </div>
        </template>
        <div v-if="userType == 1" class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
            <div class="card-header">Quick links</div>
            <div class="card-body text-center pt-2"
              style="height: 264px; overflow-y: scroll; padding-top: 0px; padding-bottom: 0px;">
              <div class="row">
                <div class="col-6" v-if="userType == 1" @click="$router.push({ name: 'Announcements' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-bell text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-left" style="align-self: center;">
                        <span>Announcements</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Attendance')" class="col-6">
                  <div @click="
      userType == 1
        ? $router.push({ name: 'Attendance' })
        : $router.push({ name: 'LogReport' })
      " class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div v-if="userType == 1" class="row">
                      <i class="fal fa-clipboard-user text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Attendance</span>
                      </div>
                    </div>
                    <div v-if="userType != 1" class="row">
                      <i class="fal fa-clipboard-user text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Attendance</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div @click="
      $router.push({
        name: 'Onboarding',
        query: { view: 'employee' }
      })
      " v-if="userType == 1" class="col-6">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-users text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Employees</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Absence or Leave')" class="col-6"
                  @click="$router.push({ name: 'Absence or Leave' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-island-tropical text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Absence or Leave</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Assets')" class="col-6" @click="$router.push({ name: 'Assets' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-computer-speaker text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Assets</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6" @click="$router.push({ name: 'Profile' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-id-card text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Profile</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6" @click="$router.push({ name: 'Events' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-calendar-alt text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Events</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Timesheets')" class="col-6"
                  @click="$router.push({ name: 'Timesheets' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-file-lines text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Timesheets</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Tasksheets')" class="col-6"
                  @click="$router.push({ name: 'Tasksheets' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-file-spreadsheet text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Tasksheets</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Dockets')" class="col-6" @click="$router.push({ name: 'Dockets' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-scale-balanced text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Dockets</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Medicals')" class="col-6" @click="$router.push({ name: 'Medicals' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-notes-medical text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Medicals</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Projects')" class="col-6" @click="$router.push({ name: 'Projects' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-tasks text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Projects</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Travel Management')" class="col-6"
                  @click="$router.push({ name: 'Travel Management' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-plane-departure text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Travel Management</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Payroll')" class="col-6" @click="$router.push({ name: 'Payroll' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-money-check-edit text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Payroll</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Loan')" class="col-6" @click="$router.push({ name: 'Loan' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-coins text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Loan</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="check_all_services('Requisitions')" class="col-6"
                  @click="$router.push({ name: 'Requisitions' })">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2">
                    <div class="row">
                      <i class="fal fa-money-check text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Requisitions</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="userType == 1" class="col-6">
                  <div class="px-1 py-5 rounded align-items-center ql-box pl-2"
                    @click="$router.push({ name: 'Settings' })">
                    <div class="row">
                      <i class="fal fa-cog text-25 col-lg-3 col-md-3 col-12 py-0"></i>
                      <div class="col-lg-9 col-md-9 col-12 py-0 text-lg-left text-md-left text-center"
                        style="align-self: center;">
                        <span>Settings</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-else>
          <div v-if="approvedEmployeePayRuns.length >= 1" class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header">Payslips</div>
              <div v-if="approvedEmployeePayRuns.length == 0" class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">
                  No payslip found.
                </p>
              </div>
              <div v-else class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
                <div class="ul-widget__body">
                  <div v-for="pr in approvedEmployeePayRuns" :key="pr.id" class="__g-widget4">
                    <div
                      @click="(currentPayRunId = pr.id, currentPayCalendarId = pr.paycalendar_id, payslip_dialog = true)"
                      class="ul-widget4__item">
                      <div class="ul-widget4__pic-icon">
                        <i class="fad fa-money-check-edit text-info"></i>
                      </div>
                      <a class="ul-widget4__title" href="#">
                        {{
      pr.payrun_name + ' (' + pr.month + ', ' + pr.year + ')'
    }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
              <div class="card-header">Payslips</div>
              <div class="card-body text-center">
                <img src="/img/no-data-3.svg" />
                <p class="card-text mt-7">No payslips found.</p>
              </div>
            </div>
          </div>
        </template>
        <div class="col-lg-4 col-md-6 col-sm-6" v-if="userType == 1" @click="$router.push({ name: 'Announcements' })">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EFCEAD80;">
            <div class="card-header" style="cursor: pointer;">
              Announcements
            </div>
            <div v-if="allAnnouncementsReversed.length >= 1" class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
              <div class="ul-widget__body">
                <div class="ul-widget3">
                  <div class="ul-widget3-item mb-0" v-for="announcement in allAnnouncementsReversed"
                    :key="announcement.id">
                    <div class="ul-widget3-header pt-3">
                      <div class="ul-widget3-info">
                        <a class="__g-widget-username" style="cursor: pointer;"
                          @click="load_announcement(announcement)">
                          <span class="t-font-bolder">{{
      announcement.title.substring(0, 25) +
      (announcement.title.length > 30 ? "..." : "")
    }}</span>
                        </a>
                        <br />
                        <span class="ul-widget-notification-item-time">{{
        timeSince(
          Date.parse(
            announcement.publish_date +
            " " +
            announcement.publish_time
          )
        )
      }}
                          ago
                          <span v-if="announcement.status == 1" class="badge badge-round-success sm"
                            style="width: 10px; height: 10px;">
                            <span></span>
                          </span>
                          <span v-else class="badge badge-round-danger sm" style="width: 10px; height: 10px;">
                            <span></span>
                          </span>
                        </span>
                      </div>
                      <div class="ul-widget3-img">
                        <img id="userDropdown" :src="super_admin.profile_image_url
        ? ASSETS_URL + super_admin.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No announcements found.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6" v-if="userType != 1">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EFCEAD80;">
            <div class="card-header">Announcements</div>
            <div v-if="authCompanyAllEmployeeAnnouncements.length >= 1" class="card-body" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
              <div class="ul-widget__body">
                <div class="ul-widget3">
                  <div class="ul-widget3-item mb-0" v-for="announcement in authCompanyAllEmployeeAnnouncements"
                    :key="announcement.id">
                    <div class="ul-widget3-header pt-3">
                      <div class="ul-widget3-info">
                        <a class="__g-widget-username" style="cursor: pointer;"
                          @click="load_announcement(announcement)">
                          <span class="t-font-bolder">{{
      announcement.title.substring(0, 25) +
      (announcement.title.length > 30 ? "..." : "")
    }}</span>
                        </a>
                        <br />
                        <span class="ul-widget-notification-item-time">{{
        timeSince(
          Date.parse(
            announcement.publish_date +
            " " +
            announcement.publish_time
          )
        )
      }}
                          ago [admin]</span>
                      </div>
                      <div class="ul-widget3-img">
                        <img id="userDropdown" :src="super_admin.profile_image_url
        ? ASSETS_URL + super_admin.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No announcements found.</p>
            </div>
          </div>
        </div>
        <div v-if="userType == 1 && check_all_services('Timesheets')" class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
            <div class="card-header">Timesheets</div>
            <div v-if="true_employees < 1 || none_submitted" class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No timesheets submitted.</p>
            </div>
            <div v-else class="card-body" style="
                        height: 264px;
                        overflow-y: scroll;
                        padding-top: 0;
                        padding-bottom: 0;
                        ">
              <div class="ul-widget__body" style="
                        ">
                <div class="tab-content py-2">
                  <div class="tab-pane active show" id="__g-widget4-tab1-content">
                    <div class="ul-widget1">
                      <template v-for="person in true_employees">
                        <div :key="person.id" v-if="person.user_type != 1 && is_submitted(person.id)"
                          class="ul-widget4__item ul-widget4__users">
                          <div class="ul-widget4__img">
                            <img id="userDropdown" :src="person.profile_image_url
        ? ASSETS_URL + person.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                          </div>
                          <div class="ul-widget2__info ul-widget4__users-info">
                            <a style="cursor: pointer;" class="ul-widget2__title" @click="
      $router.push({
        name: 'ViewEmployee',
        params: { id: person.id }
      })
      ">{{ person.employee_fristname }}
                              {{ person.employee_lastname }}</a><span class="ul-widget2__username">{{
      get_dept_name(person.department_id)
    }}</span>
                          </div>
                          <div class="ul-widget4__actions">
                            <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <span class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span>
                            </button>
                            <div class="dropdown-menu" x-placement="top-start"
                              style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                              <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;" @click="
    $router.push({
      name: 'Timesheets',
      query: { name: person.employee_fristname + ' ' + person.employee_lastname }
    })
      "><i class="fas fa-eye" style="color: inherit !important;">
                                </i>
                                View Timesheet</a>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div v-if="userType == 1 && check_all_services('Tasksheets')" class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
            <div class="card-header">Tasksheets</div>
            <div v-if="true_employees < 1 || tasksheet_none_submitted" class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No tasksheets submitted.</p>
            </div>
            <div v-else class="card-body" style="
                        height: 264px;
                        overflow-y: scroll;
                        padding-top: 0;
                        padding-bottom: 0;
                        ">
              <div class="ul-widget__body" style="
                        ">
                <div class="tab-content py-2">
                  <div class="tab-pane active show" id="__g-widget4-tab1-content">
                    <div class="ul-widget1">
                      <template v-for="person in true_employees">
                        <div :key="person.id" v-if="person.user_type != 1 && tasksheet_is_submitted(person.id)"
                          class="ul-widget4__item ul-widget4__users">
                          <div class="ul-widget4__img">
                            <img id="userDropdown" :src="person.profile_image_url
        ? ASSETS_URL + person.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                          </div>
                          <div class="ul-widget2__info ul-widget4__users-info">
                            <a style="cursor: pointer;" class="ul-widget2__title" @click="
      $router.push({
        name: 'ViewEmployee',
        params: { id: person.id }
      })
      ">{{ person.employee_fristname }}
                              {{ person.employee_lastname }}</a><span class="ul-widget2__username">{{
      get_dept_name(person.department_id)
    }}</span>
                          </div>
                          <div class="ul-widget4__actions">
                            <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <span class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span><span
                                class="_dot _inline-dot bg-primary"></span>
                            </button>
                            <div class="dropdown-menu" x-placement="top-start"
                              style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                              <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;" @click="
    $router.push({
      name: 'Tasksheets',
      query: { name: person.employee_fristname + ' ' + person.employee_lastname }
    })
      "><i class="fas fa-eye" style="color: inherit !important;">
                                </i>
                                View Tasksheet</a>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EFCEAD80;">
            <div class="card-header">Upcoming events</div>
            <div v-if="upcomingEvents.length == 0" class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No upcoming events found.</p>
            </div>
            <div v-else class="card-body text-center" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
              <div class="ul-widget__body">
                <div class="ul-widget3">
                  <div class="ul-widget3-item" v-for="event in upcomingEvents" :key="event.id">
                    <div class="ul-widget3-header pt-3">
                      <div class="ul-widget3-info pr-3 text-left">
                        <a class="__g-widget-username" style="cursor: pointer;"
                          @click="$router.push({ name: 'Events' })">
                          <span class="t-font-bolder">{{
      /* event.title.substring(0, 25) +
      (event.title.length > 30 ? "..." : "") */
      event.title
    }}</span>
                        </a>
                        <br />
                        <span class="ul-widget-notification-item-time">{{
        event.start_date == event.end_date ?
          new Date(event.start_date)
            .toDateString()
            .substring(0, 16)
          :
          new Date(event.start_date)
            .toDateString()
            .substring(0, 16)
          + ' - ' +
          new Date(event.end_date)
            .toDateString()
            .substring(0, 16)
      }}
                        </span>
                      </div>
                      <div class="ul-widget3-img">
                        <img id="userDropdown" :src="super_admin.profile_image_url
        ? ASSETS_URL + super_admin.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                          style="width: 2.3rem; height: 2.3rem;" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="check_all_services('File Management')" class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
            <div class="card-header">My files</div>
            <div class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No Files Found.</p>
            </div>
          </div>
        </div>
        <div v-if="userType == 1 && check_all_services('Attendance')" class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #EBE3DB80;">
            <div class="card-header">Attendance day summary</div>
            <div v-if="day_attendance.length >= 1" class="card-body" style="
                        height: 264px;
                        overflow-y: scroll;
                        padding-top: 0;
                        padding-bottom: 0;
                        ">
              <div class="ul-widget__body" style="
                        ">
                <div class="tab-content py-2">
                  <div class="tab-pane active show" id="__g-widget4-tab1-content">
                    <div class="ul-widget1">
                      <div v-for="person in day_attendance" :key="person.id" class="ul-widget4__item ul-widget4__users">
                        <div class="ul-widget4__img">
                          <img id="userDropdown" :src="get_profile_image(person.employee_id) &&
        !!authCompanyEmployees
        ? ASSETS_URL +
        get_profile_image(person.employee_id)
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        </div>
                        <div class="ul-widget2__info ul-widget4__users-info">
                          <a class="ul-widget2__title" href="#">{{ person.employee_fristname }}
                            {{ person.employee_lastname }}</a><span class="ul-widget2__username" href="#">{{
      person.employee_department
    }}</span>
                        </div>
                        <div class="ul-widget4__actions">
                          <button class="btn _r_btn border-0" type="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <span v-if="person.status == 'early'" class="badge badge-round-success sm"
                              style="width: 10px; height: 10px;">
                              <span></span>
                            </span>
                            <span v-if="person.status == 'late'" class="badge badge-round-warning sm"
                              style="width: 10px; height: 10px;">
                              <span></span>
                            </span>
                            <span v-if="person.status == 'grace'" class="badge badge-round-warning sm"
                              style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;">
                              <span></span>
                            </span>
                          </button>
                          <div class="dropdown-menu" x-placement="top-start"
                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                            <!-- <a
                              class="dropdown-item ul-widget__link--font"
                              style="cursor: pointer;"
                               @click="$router.push({ name: 'ViewCandidate', params: { id: person.id } })"
                              ><i class="fas fa-eye" style="color: inherit !important;"> </i> View</a
                            ><a
                              class="dropdown-item ul-widget__link--font"
                              style="cursor: pointer;"
                              @click="$router.push({ name: 'EditCandidate', params: { id: person.id } })"
                              ><i class="fas fa-pencil-alt"> </i> Edit</a
                            > -->
                            <a class="dropdown-item ul-widget__link--font" style="cursor: pointer;"
                              @click="delete_inline(person.id, 2)"><i class="fas fa-trash-alt"></i> Delete</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No Data Available.</p>
            </div>
          </div>
        </div>
        <div v-if="check_all_services('Absence or Leave')" class="col-lg-4 col-md-6 col-sm-6">
          <div class="card mb-4" style="border: 4px solid #FFFFFF; background: #B7D0E180;">
            <div class="card-header">On leave today</div>
            <div v-if="on_leave_today.length >= 1" class="card-body text-center" style="
                    height: 264px;
                    overflow-y: scroll;
                    padding-top: 0;
                    padding-bottom: 0;
                    ">
              <div class="ul-widget5"
                :class="track_leave_duration(leave.leave_start, leave.leave_end) == 100 ? 'd-none' : null"
                v-for="leave in on_leave_today" :key="leave.id">
                <div class="ul-widget-s5__item">
                  <div class="col-md-6 ul-widget-s5__content pl-0">
                    <div class="ul-widget4__img pr-0">
                      <img id="userDropdown" :src="leave.profile_image_url
        ? ASSETS_URL + leave.profile_image_url
        : '/assets/images/default_profile_img.png'
      " alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                    </div>
                    <div class="ul-widget3-info text-left">
                      <a class="__g-widget-username" style="cursor: pointer;">
                        <span v-if="userType == 1" @click="
      $router.push({
        name: 'ViewEmployee',
        params: { id: leave.employee_id }
      })
      " class="t-font-bolder">{{
      getEmployeeName(leave.employee_id) ||
      leave.employee_name
    }}</span>
                        <span v-if="userType != 1" class="t-font-bolder">{{
      getEmployeeName(leave.employee_id) ||
      leave.employee_name
    }}</span>
                      </a>
                      <br />
                      <span class="ul-widget-notification-item-time">{{ get_type_name(leave.leave_type) }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 ul-widget-s5__content" style="float: right;">
                    <div class="ul-widget-s5__progress pr-0">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" :aria-valuenow="track_leave_duration(
      leave.leave_start,
      leave.leave_end
    )
      " class="progress-bar bg-secondary" :style="'width: ' +
      track_leave_duration(
        leave.leave_start,
        leave.leave_end
      ) +
      '%;'
      ">
                          {{
      track_leave_duration(
        leave.leave_start,
        leave.leave_end
      )
    }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card-body text-center">
              <img src="/img/no-data-3.svg" />
              <p class="card-text mt-7">No one is on leave today.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-row class="mx-auto" justify="center">
      <v-dialog v-model="view_announcement_dialog" max-width="600px" class="sssss">
        <v-card>
          <v-card-title>
            <span class="headline">{{ "Announcement" }} [{{ announcement_day }} -
              {{ announcement_time }}]</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col cols="12" class="remove-hint">
                      <h4>{{ announcement_title }}</h4>
                    </v-col>
                    <v-col cols="12" class="remove-hint">
                      <p>{{ announcement_body }}</p>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="view_announcement_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--  -->
      <v-dialog
        v-if="specificEmployeeAllowances && specificEmployeeDeductions && specificEmployeePayDetails && employeePayBasic"
        v-model="payslip_dialog" max-width="900px" class="">
        <v-card>
          <!-- <v-card-title>
            <span class="headline">Your payslip</span>
          </v-card-title> -->
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="m0-3" style="border-radius: 10px;">
                  <div id="printMe" class="card" style="box-shadow: none;">
                    <div class="card-header px-3">
                      <h4>Payslip summary</h4>
                    </div>
                    <div class="card-body px-3 py-1">
                      <v-row class="add-mt">
                        <v-col cols="6">
                          <v-row>
                            <v-col cols="12">
                              <h3 class="text-left">{{ authCompanyData.company_name }}</h3>
                              <p><i>{{ authCompanyData.address }}</i></p>
                            </v-col>
                            <v-col cols="12">
                              <h5 style="font-weight: bold;">Employee Name</h5>
                              <p>{{ authEmployeeDataForCompany.employee_fristname + ' ' +
      authEmployeeDataForCompany.employee_lastname
                              }}</p>
                              <h5 style="font-weight: bold;">Employee Address</h5>
                              <p><i>{{ authEmployeeDataForCompany.employee_address }}</i></p>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" class="text-center">
                          <img :src="authCompanyData.logo
        ? host_url + authCompanyData.logo
        : '/img/kylian-logo-2.png'
      " alt="" class="w-25" style="height: inherit;" />
                        </v-col>
                      </v-row>
                      <v-row class="mx-1">
                        <v-col cols="12">
                          <v-row>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Department
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Designation
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="6" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Period
                              </h5>
                            </v-col>
                            <!-- <v-col cols="4"></v-col> -->
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.department }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.designation }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="6">
                              <h5 v-if="currentPayRun">
                                {{ specificEmployeePayDetails.period }}
                              </h5>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Payment Date
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Bank Name
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Bank Account Number
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3" style="background: #e2e2e2;">
                              <h5 style="font-weight: bold;">
                                Payment Method
                              </h5>
                            </v-col>
                            <!-- <v-col cols="4"></v-col> -->
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.payment_date }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.bank_name }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.account_number }}
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="3">
                              <h5>
                                {{ specificEmployeePayDetails.payment_method }}
                              </h5>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="py-3" cols="6" style="background: #e2e2e2; border-right: 10px solid #fff;">
                              <h5 style="font-weight: bold;">
                                Payments
                              </h5>
                            </v-col>
                            <v-col class="py-3" cols="6" style="background: #e2e2e2; border-left: 10px solid #fff;">
                              <h5 style="font-weight: bold;">
                                Deductions
                              </h5>
                            </v-col>
                            <v-col cols="6" style="border-right: 10px solid #fff;">
                              <v-row style="border-top: 2px solid #fff;">
                                <v-col class="py-3" cols="6">
                                  <h5>
                                    Pay Basic
                                  </h5>
                                </v-col>
                                <v-col cols="6" class="text-right py-3">
                                  <h5>
                                    ₦{{ parseInt(employeePayBasic.amount || 0) | number_with_commas }}
                                  </h5>
                                </v-col>
                              </v-row>
                              <v-row v-for="ea in specificEmployeeAllowances" style="border-top: 2px solid #fff;"
                                :key="ea.id">
                                <v-col class="py-3" cols="6">
                                  <h5>
                                    {{ ea.allowance }}
                                  </h5>
                                </v-col>
                                <v-col cols="6" class="text-right py-3">
                                  <h5>
                                    ₦{{ (parseInt(ea.amount) || 0) | number_with_commas }}
                                  </h5>
                                </v-col>
                              </v-row>
                              <!-- <template v-for="fa in employeesFlexibleAllowances(authEmployeeDataForCompany.id)">
                                  <v-row style="border-top: 2px solid #fff;" :key="fa.id">
                                    <v-col class="py-3" cols="6">
                                      <h5>
                                        {{ fa.allowance_name }}
                                      </h5>
                                    </v-col>
                                    <v-col cols="6" class="text-right py-3">
                                      <h5>
                                        ₦{{ (parseInt(fa.allowance_amount) || 0) | number_with_commas }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                </template> -->
                              <v-row style="border-top: 2px solid #8c8c8c;">
                                <v-col class="py-3" cols="6" style="background: #e2e2e2;">
                                  <h5 style="font-weight: bold;">
                                    Gross Pay
                                  </h5>
                                </v-col>
                                <v-col cols="6" style="background: #e2e2e2;" class="text-right py-3">
                                  <h5 style="font-weight: bold;">
                                    ₦{{ parseInt(specificEmployeePayDetails.total_payment || 0) | number_with_commas }}
                                  </h5>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="6" style="border-left: 10px solid #fff;">
                              <v-row v-for="ed in specificEmployeeDeductions" style="border-top: 2px solid #fff;"
                                :key="ed.id">
                                <v-col class="py-3" cols="6">
                                  <h5>
                                    {{ ed.deduction }}
                                  </h5>
                                </v-col>
                                <v-col cols="6" class="text-right py-3">
                                  <h5>
                                    ₦{{ (parseInt(ed.amount) || 0) | number_with_commas }}
                                  </h5>
                                </v-col>
                              </v-row>
                              <v-row style="border-top: 2px solid #8c8c8c;">
                                <v-col class="py-3" cols="6" style="background: #e2e2e2;">
                                  <h5 style="font-weight: bold;">
                                    Total Deductions
                                  </h5>
                                </v-col>
                                <v-col cols="6" style="background: #e2e2e2;" class="text-right py-3">
                                  <h5 style="font-weight: bold;">
                                    ₦{{ parseInt(specificEmployeePayDetails.total_deduction || 0) | number_with_commas
                                    }}
                                  </h5>
                                </v-col>
                              </v-row>
                              <v-row style="border-top: 2px solid #8c8c8c;">
                                <v-col class="py-3" cols="6" style="background: #e2e2e2;">
                                  <h5 style="font-weight: bold;">
                                    Net Pay
                                  </h5>
                                </v-col>
                                <v-col cols="6" style="background: #e2e2e2;" class="text-right py-3">
                                  <h5 style="font-weight: bold;">
                                    ₦{{ parseInt(specificEmployeePayDetails.net_pay || 0) | number_with_commas }}
                                  </h5>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="payslip_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="comment_dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ approval ? 'Approve' : 'Reject' }} requisition with comment</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col cols="12" class="remove-hint">
                      <span style="color: #111d5e;">Requisition Comment <i class="fas fa-asterisk"
                          style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                      <v-textarea v-model="comment"
                        :placeholder="(userType == 1 || (userType != 1 && permissionIsAssigned('requisition-approve'))) ? 'Requisition Comment' : 'No comment'"
                        solo class="mt-2"
                        :disabled="(userType == 1 || (userType != 1 && permissionIsAssigned('requisition-approve'))) ? false : true"></v-textarea>
                    </v-col>
                    <v-col v-if="userType == 1 || (userType != 1 && permissionIsAssigned('requisition-approve'))"
                      cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                      <button @click="add_comment_submit ? null : requisition_action()" style="width: 99%;"
                        type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                        {{ add_comment_text }}
                        <v-icon :style="!add_comment_submit
        ? 'display: none;'
        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
      ">
                          fal fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="comment_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <div class="flex-grow-1"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { ASSETS_URL, BASE_URL } from "@/main";
import axios from "axios";
export default {
  data() {
    return {
      comment: '',
      approval: false,
      current_requisition: null,
      comment_dialog: false,
      add_comment_text: 'Submit',
      add_comment_submit: false,
      geo_location_latitude: '',
      geo_location_longitude: '',
      geo_location_address: '',
      currentPayCalendarId: null,
      currentPayRunId: null,
      refreshing_page: false,
      ASSETS_URL,
      formData: {
        checkInNote: ""
      },
      checkInText: "Check-in",
      checkInLoading: false,
      checkOutLoading: false,
      checkOutText: "Check-out",
      timeSpent: "",
      announcement_title: "",
      announcement_body: "",
      announcement_day: "",
      announcement_time: "",
      view_announcement_dialog: false,
      day_attendance: [],
      payslip_dialog: false
    };
  },
  computed: {
    ...mapState([
      "weekDays"
    ]),
    ...mapGetters([
      "validEmployeeId",
      "authCompanyAttendanceConfig",
      "authCompanyEmployees",
      "authCompanyCandidates",
      "authCompanyDepartments",
      "authCompanyCommittees",
      "userType",
      "checkedIn",
      "authEmployeeDataForCompany",
      "checkInMs",
      "authCompanyAllEmployeeAnnouncements",
      "authCompanyAllAnnouncements",
      "authCompanyApprovedLeave",
      "authCompanyAllLeave",
      "authCompanyAllLeaveType",
      "authCompanyData",
      "authCompanyAllEvents",
      "authCompanyAllBilling",
      "true_employees",
      "authCompanyAllTimesheets",
      "authUserData",
      "authPayCalendars",
      "authEmployeePayCalendars",
      "authFixedAllowances",
      "authFixedDeductions",
      "authFlexibleAllowances",
      "authFlexibleDeductions",
      "authEmployeeTaxes",
      "authCompanyPayRun",
      "authCompanyDepartments",
      "authDesignations",
      "authEmployeePayslipDetails",
      "authSubscriptionExpired",
      "authCheckinAllTime",
      "authCompanyLoans",
      "authEmployeeCommittees",
      "authCompanyDepartmentEmployees",
      "authCompanyTaskSheets",
      "authCompanyRequisitions",
      "authCompanyRequisitionTypes",
      "authCompanyDockets",
      "authCompanyServices"
    ]),
    host_url() {
      return ASSETS_URL;
    },
    yearSelected() {
      return new Date().getFullYear();
    },
    monthSelected() {
      return this.monthNames[new Date().getMonth()];
    },
    filtered_requisitions() {
      let _this = this, requisitions = _this.authCompanyRequisitions;
      requisitions = requisitions.filter(r => r.requisition.approval_status == 0);
      return requisitions;
    },
    employeeDepartments() {
      let _this = this, result = [], filtered_results = [];
      if (_this.authEmployeeDataForCompany.department_id) {
        result.splice(0, 0, {
          id: _this.authEmployeeDataForCompany.department_id,
          primary_member: true
        });
      }
      filtered_results = _this.authCompanyDepartmentEmployees.filter(item => item.employee_id == _this.validEmployeeId);
      filtered_results = filtered_results.map(item => {
        return {
          id: item.department_id,
          primary_member: false
        }
      });
      result = [...result, ...filtered_results];
      return result;
    },
    employeeCommittees() {
      let _this = this, result = [], filtered_results = [];
      filtered_results = _this.authEmployeeCommittees.filter(item => item.employee_id == _this.validEmployeeId);
      result = filtered_results.map(item => {
        const committee = _this.get_committee(item.committee_id);
        return {
          id: item.committee_id,
          department_id: committee ? committee.department_id : 0
          // GET VALUE ABOVE
        }
      })
      return result;
    },
    employeeDepartmentsIds() {
      return this.employeeDepartments.map(ed => {
        return ed.id
      });
    },
    employeeCommitteesIds() {
      return this.employeeCommittees.map(ec => {
        return ec.id
      });
    },
    otherDepartments() {
      let _this = this, result = [];
      result = _this.authCompanyDepartments.filter(d => {
        return _this.employeeDepartmentsIds.indexOf(d.id) === -1
      });
      return result;
    },
    otherCommittees() {
      let _this = this, result = [];
      result = _this.authCompanyCommittees.filter(c => {
        return _this.employeeCommitteesIds.indexOf(c.id) === -1
      });
      return result;
    },
    transformedCheckinAllTime() {
      let _this = this, result = [], daysInYear = [];
      _this.monthNames.forEach(month => {
        if (month.toLowerCase() !== 'all year') {
          daysInYear.splice(daysInYear.length, 0, ...(_this.getDaysInMonth(_this.monthNames.indexOf(month), _this.yearSelected)))
        }
      });
      daysInYear.forEach(day => {
        let checkins = _this.getCheckInByDay(day);
        let mappedCheckIns = checkins.map((c, index) => {
          return {
            ...c,
            indexTrackerValue: (_this.true_employees.length - index)
          }
        });
        result.splice(result.length, 0, ...mappedCheckIns)
      });
      return result;
    },
    filteredTransformedCheckinAllTimeEarly() {
      let _this = this, result = [];
      if (_this.monthSelected.toLowerCase() === 'all year') {
        result = _this.transformedCheckinAllTime.filter(t => {
          return t.year == _this.yearSelected
        });
      } else {
        result = _this.transformedCheckinAllTime.filter(t => {
          return t.year == _this.yearSelected && t.month_name == _this.monthSelected
        });
      }
      return result;
    },
    filteredTransformedCheckinAllTimeLate() {
      let _this = this, result = [];
      if (_this.monthSelected.toLowerCase() === 'all year') {
        result = _this.transformedCheckinAllTime.filter(t => {
          return t.year == _this.yearSelected
        });
      } else {
        result = _this.transformedCheckinAllTime.filter(t => {
          return t.year == _this.yearSelected && t.month_name == _this.monthSelected
        });
      }
      return result;
    },
    topEarliestEmployees() {
      // let _this = this, legitEmployees = [], noCheckInEmployees = [];
      // legitEmployees = _this.employeesWithAvgTime.filter(e => e.avgTime);
      // noCheckInEmployees = _this.employeesWithAvgTime.filter(e => !e.avgTime);
      // legitEmployees.sort((a, b) => {
      //   return a.timeInMilliseconds - b.timeInMilliseconds
      // });
      // legitEmployees.splice(legitEmployees.length, 0, ...noCheckInEmployees);
      // return legitEmployees;
      let _this = this, result = [], dateToday = new Date().toDateString();
      _this.true_employees.forEach(e => {
        let employeeCheckins = _this.getEmployeeCheckinsEarly(e.id);
        let allIndexTrackerValue = 0;
        let newEmployeeObj
        employeeCheckins.forEach(c => {
          allIndexTrackerValue += c.indexTrackerValue
        });
        newEmployeeObj = {
          ...e,
          avgTime: _this.getAverageTime(e.id, _this.yearSelected, _this.monthSelected),
          timeInMilliseconds: new Date(dateToday + ' ' + _this.getAverageTime(e.id, _this.yearSelected, _this.monthSelected)).getTime(),
          avgIndexTrackerValue: (allIndexTrackerValue / employeeCheckins.length) || 0
        }
        result.splice(result.length, 0, newEmployeeObj);
      });
      result.sort((a, b) => {
        return b.avgIndexTrackerValue - a.avgIndexTrackerValue
      });
      return result;
    },
    topLatestEmployees() {
      // let _this = this, legitEmployees = [], noCheckInEmployees = [];
      // legitEmployees = _this.employeesWithAvgTime.filter(e => e.avgTime);
      // noCheckInEmployees = _this.employeesWithAvgTime.filter(e => !e.avgTime);
      // legitEmployees.sort((a, b) => {
      //   return b.timeInMilliseconds - a.timeInMilliseconds
      // });
      // legitEmployees.splice(legitEmployees.length, 0, ...noCheckInEmployees);
      // return legitEmployees;
      let _this = this, result = [], dateToday = new Date().toDateString();
      _this.true_employees.forEach(e => {
        let employeeCheckins = _this.getEmployeeCheckinsLate(e.id);
        let allIndexTrackerValue = 0;
        let newEmployeeObj
        employeeCheckins.forEach(c => {
          allIndexTrackerValue += c.indexTrackerValue
        });
        newEmployeeObj = {
          ...e,
          avgTime: _this.getAverageTime(e.id, _this.yearSelected, _this.monthSelected),
          timeInMilliseconds: new Date(dateToday + ' ' + _this.getAverageTime(e.id, _this.yearSelected, _this.monthSelected)).getTime(),
          avgIndexTrackerValue: (allIndexTrackerValue / employeeCheckins.length) || 0
        }
        result.splice(result.length, 0, newEmployeeObj);
      });
      result.sort((a, b) => {
        return a.avgIndexTrackerValue - b.avgIndexTrackerValue
      });
      return result;
    },
    topTardyEmployees() {
      let _this = this, result = [];
      result = _this.topLatestEmployees.filter(e => {
        return _this.displayOnTardyList(e.timeInMilliseconds);
      });
      return result;
    },
    employeesWithAvgTime() {
      let _this = this, result = [], yearSelected = new Date().getFullYear(), monthSelected = _this.monthNames[new Date().getMonth()], dateToday = new Date().toDateString();
      result = _this.true_employees.map(te => {
        return {
          ...te,
          avgTime: _this.getAverageTime(te.id, yearSelected, monthSelected),
          timeInMilliseconds: new Date(dateToday + ' ' + _this.getAverageTime(te.id, yearSelected, monthSelected)).getTime()
        }
      });
      return result;
    },
    allEmployeeAllowances() {
      let _this = this, result = [];
      if (_this.authEmployeePayslipDetails) {
        if (_this.authEmployeePayslipDetails.employeeallowances) {
          result = _this.authEmployeePayslipDetails.employeeallowances.filter(p => p.employee_id == _this.authEmployeeDataForCompany.id);
        }
      }
      return result;
    },
    employeePayBasic() {
      let _this = this, result = [];
      if (_this.allEmployeeAllowances) {
        result = _this.allEmployeeAllowances.filter(a => a.payrun_id == _this.currentPayRunId && a.allowance.toLowerCase() == 'pay basic')
        if (result.length >= 1) {
          return result[0];
        }
      }
      return null;
    },
    specificEmployeeAllowances() {
      let _this = this, result = [];
      if (_this.allEmployeeAllowances) {
        result = _this.allEmployeeAllowances.filter(a => a.payrun_id == _this.currentPayRunId && a.allowance.toLowerCase() != 'pay basic')
      }
      return result
    },
    allEmployeePayDetails() {
      let _this = this, result = [];
      if (_this.authEmployeePayslipDetails) {
        if (_this.authEmployeePayslipDetails.employeepayslip) {
          result = _this.authEmployeePayslipDetails.employeepayslip.filter(p => p.employee_id == _this.authEmployeeDataForCompany.id);
        }
      }
      return result;
    },
    specificEmployeePayDetails() {
      let _this = this, result = [];
      if (_this.allEmployeePayDetails) {
        result = _this.allEmployeePayDetails.filter(pd => pd.payrun_id == _this.currentPayRunId)
        if (result.length >= 1) {
          return result[0];
        }
      }
      return null;
    },
    allEmployeeDeductions() {
      let _this = this, result = [];
      if (_this.authEmployeePayslipDetails) {
        if (_this.authEmployeePayslipDetails.employeedeductions) {
          result = _this.authEmployeePayslipDetails.employeedeductions.filter(p => p.employee_id == _this.authEmployeeDataForCompany.id)
        }
      }
      return result;
    },
    specificEmployeeDeductions() {
      let _this = this, result = [];
      if (_this.allEmployeeDeductions) {
        result = _this.allEmployeeDeductions.filter(d => d.payrun_id == _this.currentPayRunId)
      }
      return result
    },
    allEmployeeTaxes() {
      let _this = this, result = [];
      if (_this.authEmployeePayslipDetails) {
        if (_this.authEmployeePayslipDetails.employeetaxes) {
          result = _this.authEmployeePayslipDetails.employeetaxes.filter(p => p.employee_id == _this.authEmployeeDataForCompany.id);
        }
      }
      return result;
    },
    specificEmployeeTaxes() {
      let _this = this, result = [];
      if (_this.allEmployeeTaxes) {
        result = _this.allEmployeeTaxes.filter(t => t.payrun_id == _this.currentPayRunId)
      }
      return result
    },
    currentPayCalendar() {
      let _this = this, result = null;
      if (_this.authPayCalendars.length >= 1) {
        result = _this.authPayCalendars.filter(pc => {
          return pc.id == _this.currentPayCalendarId
        })[0];
      }
      return result;
    },
    approvedPayRuns() {
      let result = [];
      if (this.authCompanyPayRun.length >= 1) {
        result = this.authCompanyPayRun.filter(pr => {
          return pr.approved == 1 && pr
        });
      }
      return result;
    },
    approvedEmployeePayRuns() {
      let _this = this, result = [];
      if (_this.approvedPayRuns.length >= 1) {
        result = _this.approvedPayRuns.filter(pr => {
          let employeeIsInCalendar = _this.employeeIsInCalendar(_this.validEmployeeId, pr.paycalendar_id);
          let employeePayslips = _this.authEmployeePayslipDetails.employeepayslip ? _this.authEmployeePayslipDetails.employeepayslip : [];
          let employeePayslipIsFound = employeePayslips.filter(payslip => {
            return (
              payslip.employee_id == _this.validEmployeeId
              &&
              payslip.payrun_id == pr.id
            );
          }).length > 0;
          return employeeIsInCalendar && employeePayslipIsFound;
        });

        // if (_this.yearSelected && _this.monthSelected) {
        //   result = result.filter(r => {
        //     return r.year == _this.yearSelected && r.month == _this.monthSelected
        //   })
        // } else {
        //   result = result.filter(r => {
        //     return r.year == _this.yearSelected
        //   })
        // }
        return result;
      } else {
        return result;
      }
    },
    payRunCalendars() {
      let _this = this, result = [], payCalendar = null;
      _this.approvedPayRuns.forEach(ap => {
        payCalendar = _this.authPayCalendars.filter(pc => {
          return pc.id == ap.paycalendar_id
        })[0]
        if (payCalendar && result.indexOf(payCalendar) == -1) {
          result.splice(result.length, 0, payCalendar);
        }
      });
      return result;
    },
    employeePayCalendarDetails() {
      let _this = this, result = null;
      if (_this.authEmployeePayCalendars.length >= 1) {
        result = _this.authEmployeePayCalendars.filter(epc => {
          return epc.employee_id == _this.authEmployeeDataForCompany.id
        })[0]
      }
      return result;
    },
    employeePayslips() {
      let _this = this, result = [];
      console.log(_this);
      return result;
    },
    currentPayRun() {
      let _this = this, result = null;
      if (_this.authCompanyPayRun.length >= 1) {
        result = _this.authCompanyPayRun.filter(pr => {
          return pr.id == _this.currentPayRunId
        })[0];
      }
      return result;
    },
    upcomingEvents() {
      let _this = this, events = []
      if (_this.authCompanyAllEvents) {
        events = _this.authCompanyAllEvents.filter(e => {
          return new Date(e.start_date).getTime() > new Date().getTime();
        });
      }
      return events;
    },
    none_submitted() {
      let _this = this, unsubmitted = [];
      unsubmitted = _this.true_employees.filter(employee => {
        return _this.is_submitted(employee.id) == false;
      });
      if (unsubmitted.length == _this.true_employees.length) {
        return true;
      }
      return false;
    },
    tasksheet_none_submitted() {
      let _this = this, unsubmitted = [];
      unsubmitted = _this.true_employees.filter(employee => {
        return _this.tasksheet_is_submitted(employee.id) == false;
      });
      if (unsubmitted.length == _this.true_employees.length) {
        return true;
      }
      return false;
    },
    authCompanyCandidates2() {
      if (this.authCompanyCandidates) {
        return this.authCompanyCandidates.filter(candidate => {
          return candidate.user_type != 1;
        });
      } else {
        return [];
      }
    },
    super_admin() {
      let _this = this;
      if (_this.authCompanyEmployees) {
        return _this.authCompanyEmployees.filter(person => {
          return person.user_type == 1;
        })[0];
      } else {
        return { profile_image_url: '/assets/images/default_profile_img.png' };
      }
    },
    allAnnouncementsReversed() {
      let _this = this;
      if (_this.authCompanyAllAnnouncements) {
        let reversed = [..._this.authCompanyAllAnnouncements];
        // let reversed = _this.authCompanyAllAnnouncements.reverse();
        return reversed.reverse();
        // return arr;
      } else {
        return "";
      }
    },
    birthday_employees() {
      function compare(a, b) {
        if (new Date(a.employee_date_of_birth).getTime() < new Date(b.employee_date_of_birth).getTime()) {
          return -1;
        }
        if (new Date(a.employee_date_of_birth).getTime() > new Date(b.employee_date_of_birth).getTime()) {
          return 1;
        }
        return 0;
      }
      let _this = this;
      if (this.authCompanyEmployees) {
        let birthdays = _this.authCompanyEmployees.filter(employee => {
          if (employee.employee_date_of_birth) {
            return (
              parseInt(employee.employee_date_of_birth.substring(5, 7)) ==
              new Date().getMonth() + 1
            );
          }
        });
        return birthdays.sort(compare);
      }
      return [];
    },
    pending_leave() {
      let _this = this;
      if (this.userType == 1) {
        if (_this.authCompanyAllLeave) {
          return _this.authCompanyAllLeave.filter(
            leave => leave.status == null
          );
        }
        return [];
      }
      if (this.userType != 1) {
        if (_this.authCompanyAllLeave) {
          return _this.authCompanyAllLeave.filter(
            leave =>
              leave.status == null &&
              leave.employee_id == _this.authEmployeeDataForCompany.id
          );
        }
        return [];
      }
      return [];
    },
    on_leave_today() {
      let _this = this;
      let leave_today = [];
      if (_this.authCompanyApprovedLeave) {
        _this.authCompanyApprovedLeave.forEach(leave => {
          let daylist = _this.getDaysArray(
            leave.leave_start,
            leave.leave_end
          );
          daylist.forEach(day => {
            if (
              new Date().toString().substr(0, 15) ==
              day.toString().substr(0, 15)
            ) {
              leave_today.push(leave);
            }
          });
        });
      }
      return leave_today;
    }
    // reversedCompanyAnnouncements() {
    //   if (this.userType == 1 && this.authCompanyAllEmployeeAnnouncements) {
    //     return this.authCompanyAllAnnouncements.reverse();
    //   }
    //   return []
    // }
  },
  watch: {
    checkedIn(new_value) {
      if (new_value) {
        this.check_is_today()
      }
    }
  },
  methods: {
    ...mapActions([
      "fetch_candidates",
      "fetch_employees",
      "fetch_active_company_employees",
      "fetch_all_leave",
      "fetch_all_approved_leave",
      "fetch_available_services",
      "refresh_company_data",
      "refresh_checkin",
      "fetch_company_requisitions",
      "fetch_company_requisition_types"
    ]),
    ...mapMutations([
      "GET_CHECK_IN_STATUS",
      "SET_CHECK_IN_TIME"
    ]),
    requisition_action() {
      const _this = this, Toast = _this.$swal.mixin({
        toast: !0,
        position: "top-end",
        showConfirmButton: !1,
        timer: 2e3,
        timerProgressBar: !0,
        didOpen: function (t) {
          t.addEventListener("mouseenter", _this.$swal.stopTimer), t.addEventListener("mouseleave", _this.$swal.resumeTimer);
        },
      });
      _this.add_comment_text = '';
      _this.add_comment_submit = true;
      if (_this.approval) {
        axios
          .post(`${BASE_URL}approverequisition`, {
            ids: [
              {
                id: _this.current_requisition.requisition.id,
                comment: _this.comment
              }
            ],
            approve_by: _this.authUserData.name
          })
          .then(function () {
            Toast.fire({ icon: "success", title: "Requisition approved successfully" });
            _this.comment = '';
            _this.comment_dialog = false;
          })
          .catch(function () {
            Toast.fire({ icon: "error", title: "Requisition couldn't be approved" });
          })
          .then(function () {
            _this.add_comment_submit = false;
            _this.add_comment_text = 'Submit';
            _this.fetch_company_requisitions(), _this.fetch_company_requisition_types();
          });
      } else {
        axios
          .post(`${BASE_URL}disapproverequisition`, {
            ids: [
              {
                id: _this.current_requisition.requisition.id,
                comment: _this.comment
              }
            ]
          })
          .then(function () {
            Toast.fire({ icon: "success", title: "Requisition rejected successfully" });
            _this.comment = '';
            _this.comment_dialog = false;
          })
          .catch(function () {
            Toast.fire({ icon: "error", title: "Requisition couldn't be rejected" });
          })
          .then(function () {
            _this.add_comment_submit = false;
            _this.add_comment_text = 'Submit';
            _this.fetch_company_requisitions(), _this.fetch_company_requisition_types();
          });
      }
    },
    approve_requisition(requisition) {
      const _this = this;
      _this.$swal
        .fire({
          title: "Are you sure you want to approve this requisition?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Approve",
        })
        .then(function (result) {
          _this.approval = true;
          _this.current_requisition = requisition;
          result.isConfirmed ? _this.comment_dialog = true : '';
        });
    },
    disapprove_requisition(requisition) {
      const _this = this;
      _this.$swal
        .fire({
          title: "Are you sure you want to reject this requisition?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Reject",
        })
        .then(function (result) {
          _this.approval = false;
          _this.current_requisition = requisition;
          result.isConfirmed ? _this.comment_dialog = true : '';
        });
    },
    getRequisitionTypeName(id) {
      let _this = this, result = [], value = null;
      result = _this.authCompanyRequisitionTypes.filter(type => type.id == id);
      if (result.length) {
        value = result[0].name;
      }
      return value;
    },
    get_docket_date(date) {
      let _this = this, value = '', dateToArray = date.split('-'), gmtString = new Date(date).toGMTString(), gmtDay = gmtString.substring(0, 3);
      let weekDay = _this.weekDays.filter(day => {
        return day.indexOf(gmtDay) !== -1
      })[0];
      value = `${weekDay} ${dateToArray[2]}/${dateToArray[1]}/${dateToArray[0]}`;
      value = value.toUpperCase();
      return value;
    },
    employeeIsInCalendar(employee_id, paycalendar_id) {
      let _this = this, truth = false;
      _this.authEmployeePayCalendars.forEach(cal => {
        if (cal.employee_id == employee_id && cal.paycalendar_id == paycalendar_id) {
          truth = true;
        }
      });
      return truth;
    },
    employeesInPayCalendar(id) {
      let _this = this,
        result = [];
      if (_this.authEmployeePayCalendars.length >= 1) {
        result = _this.authEmployeePayCalendars.filter(
          (pc) => pc.paycalendar_id == id
        );
      }
      return result;
    },
    locateAndCheckin(time, today, msTime) {
      let _this = this;
      // document.getElementById("btnAction").disabled = true;
      if ("geolocation" in window.navigator) {
        window.navigator.geolocation.getCurrentPosition(function (position) {
          let currentLatitude = position.coords.latitude;
          let currentLongitude = position.coords.longitude;

          _this.geo_location_latitude = currentLatitude;
          _this.geo_location_longitude = currentLongitude;
          _this.getReverseGeocodingData(currentLatitude, currentLongitude, time, today, msTime)

          // var infoWindowHTML = "Latitude: " + currentLatitude + "<br>Longitude: " + currentLongitude;
          // var infoWindow = new google.maps.InfoWindow({map: map, content: infoWindowHTML});
          // var currentLocation = { lat: currentLatitude, lng: currentLongitude };
          // infoWindow.setPosition(currentLocation);
          // document.getElementById("btnAction").style.display = 'none';
        }, function () {
          _this.realCheckin(time, today, msTime);
        });
      }
    },
    getReverseGeocodingData(lat, lng, time, today, msTime) {
      let _this = this;
      let latlng = new window.google.maps.LatLng(lat, lng);
      // This is making the Geocode request
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ 'latLng': latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
          // alert(status);
          // Toast.fire({
          //   icon: "error",
          //   title: status
          // });
          _this.realCheckin(time, today, msTime);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == window.google.maps.GeocoderStatus.OK) {
          //console.log(results);
          let address = (results[0].formatted_address);
          _this.geo_location_address = address;

          _this.realCheckin(time, today, msTime);
        }


      });
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
    displayOnTardyList(timeInMilliseconds) {
      let _this = this, dateToday = new Date().toDateString(), graceInMilli = (_this.authCompanyAttendanceConfig.grace_period || 0) * 60 * 1000;
      let fullResumptionTimeInMilli = new Date(dateToday + ' ' + _this.authCompanyAttendanceConfig.resumption_time).getTime() + graceInMilli;
      return timeInMilliseconds > fullResumptionTimeInMilli;
    },
    getCheckInByDay(day) {
      let _this = this, result = [];
      if (_this.authCheckinAllTime.length) {
        result = _this.authCheckinAllTime.filter(checkin => {
          return checkin.day === new Date(day).toISOString().substring(0, 10);
        });
      }
      return result;
    },
    getDaysInMonth(month, year) {
      let date = new Date(year, month, 1);
      let days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    getEmployeeCheckinsEarly(employee_id) {
      let _this = this, result = [];
      result = _this.filteredTransformedCheckinAllTimeEarly.filter(t => {
        return t.employee_id == employee_id
      })
      return result;
    },
    getEmployeeCheckinsLate(employee_id) {
      let _this = this, result = [];
      result = _this.filteredTransformedCheckinAllTimeLate.filter(t => {
        return t.employee_id == employee_id
      })
      return result;
    },
    convertToAMPM(time) {
      let initSplit = time.split(' '), trueTime = time;
      if (initSplit.length === 2) {
        trueTime = initSplit[1];
      }
      let split = trueTime.split(':'), firstNumber = split[0], newFirstNumber = '', result = '';
      if (parseInt(firstNumber) >= 13) {
        newFirstNumber = firstNumber - 12;
      }
      result = firstNumber >= 13 ? (newFirstNumber < 10 ? '0' + newFirstNumber : newFirstNumber) + ':' + split[1] + ':' + split[2] + ' PM' : (firstNumber < 10 ? '0' + firstNumber : firstNumber) + ':' + split[1] + ':' + split[2] + ' AM';
      return result;
    },
    getAverageTime(employeeId, year, month) {
      let _this = this, filteredCheckIns = [];
      filteredCheckIns = _this.authCheckinAllTime.filter(c => {
        return c.employee_id == employeeId && c.year == year && c.month_name == month
      });

      const times = filteredCheckIns.map(fc => _this.convertToAMPM(fc.check_in));

      let count = times.length
      if (!count) {
        return null;
      }
      let timesInSeconds = [];
      // loop through times
      for (let i = 0; i < count; i++) {
        // parse
        let pieces = times[i].split(':');
        let ampm = pieces[2].split(' ');
        let hrs = Number(pieces[0]);
        let mins = Number(pieces[1]);
        let secs = Number(ampm[0]);
        ampm = ampm[1];
        // convert to 24 hr format (military time)
        if (ampm == 'PM') hrs = hrs + 12;
        // find value in seconds of time
        let totalSecs = hrs * 60 * 60;
        totalSecs += mins * 60;
        totalSecs += secs;
        // add to array
        timesInSeconds[i] = totalSecs;
      }
      // find average timesInSeconds
      let total = 0;
      for (let j = 0; j < count; j++) {
        total = total + Number(timesInSeconds[j]);
      }
      let avg = Math.round(total / count);
      // turn seconds back into a time
      let avgMins = Math.floor(avg / 60);
      let avgSecs = avg - (60 * avgMins);
      let avgHrs = Math.floor(avgMins / 60);
      avgMins = avgMins - (60 * avgHrs);
      // convert back to 12 hr. format
      let avgAmpm = 'AM';
      if (avgHrs > 12) {
        avgAmpm = 'PM';
        avgHrs = avgHrs - 12;
      }
      // add leading zeros for seconds, minutes
      avgSecs = ('0' + avgSecs).slice(-2);
      avgMins = ('0' + avgMins).slice(-2);
      // your answer
      return avgHrs + ':' + avgMins + ':' + avgSecs + ' ' + avgAmpm;
    },
    // getAvgTime(employeeId, year, month) {
    //   let _this = this, filteredCheckIns = [], allTimeToAdd = 0, averageMilliseconds = 0
    //   filteredCheckIns = _this.authCheckinAllTime.filter(c => {
    //     return c.employee_id == employeeId && c.year == year && c.month_name == month
    //   });
    //   filteredCheckIns.forEach(c => {
    //     allTimeToAdd += new Date(c.ms_time).getTime();
    //   });
    //   averageMilliseconds = filteredCheckIns.length ? allTimeToAdd/filteredCheckIns.length : null;
    //   return averageMilliseconds
    // },
    currentEmployeeFixedDeductions(id) {
      let _this = this, result = [];
      if (_this.authFixedDeductions.length >= 1) {
        result = _this.authFixedDeductions.filter(fd => fd.employee_id == id);
      }
      return result;
    },
    currentEmployeeFixedTax(id) {
      let _this = this, result = [];
      if (_this.authEmployeeTaxes.length >= 1) {
        result = _this.authEmployeeTaxes.filter(t => t.employee_id == id);
      }
      return result;
    },
    // get_dept_name(dept_id) {
    //   let _this = this, dept;
    //   if (_this.authCompanyDepartments) {
    //     dept = _this.authCompanyDepartments.filter(dept => {
    //       return dept.id == dept_id;
    //     });
    //   }
    //   if (dept.length >= 1) {
    //     return dept[0].department_name;
    //   }
    //   return null;
    // },
    currentEmployeeFixedAllowances(id) {
      let _this = this, result = [];
      if (_this.authFixedAllowances.length >= 1) {
        result = _this.authFixedAllowances.filter(fa => fa.employee_id == id);
      }
      return result;
    },
    finalEmployeeNetPay(payments, deductions, tax) {
      let result = parseInt(payments) - parseInt(deductions) - parseInt(tax);
      return result;
    },
    employeeFixedDeductions(id) {
      let _this = this, result = [], value = 0;
      if (_this.authFixedDeductions.length >= 1) {
        result = _this.authFixedDeductions.filter(fd => fd.employee_id == id);
        result.forEach(fdr => {
          value += parseInt(fdr.deduction_amount);
        })
      }
      return value;
    },
    employeeFixedTax(id) {
      let _this = this, result = [], value = 0;
      if (_this.authEmployeeTaxes.length >= 1) {
        result = _this.authEmployeeTaxes.filter(t => t.employee_id == id);
        result.forEach(tr => {
          value += parseInt(tr.amount);
        })
      }
      return value;
    },
    employeesFlexibleAllowances(employee_id) {
      let _this = this, result = [];
      if (_this.authFlexibleAllowances.length >= 1) {
        result = _this.authFlexibleAllowances.filter(fa => {
          return fa.employee_id == employee_id && fa.payrun_id == _this.currentPayRunId && fa.paycalendar_id == _this.currentPayCalendar.id
        });
      }
      return result;
    },
    employeesFlexibleDeductions(employee_id) {
      let _this = this, result = [];
      if (_this.authFlexibleDeductions.length >= 1) {
        result = _this.authFlexibleDeductions.filter(fd => {
          return fd.employee_id == employee_id && fd.payrun_id == _this.currentPayRunId && fd.paycalendar_id == _this.currentPayCalendar.id
        });
      }
      return result;
    },
    totalAllowanceToPay(fixed, flexibles, salary) {
      let result = 0;
      if (flexibles.length >= 1) {
        flexibles.forEach(fa => {
          result += parseInt(fa.allowance_amount)
        });
      }
      result += parseInt(fixed);
      result += parseInt(salary);
      return result;
    },
    totalDeductionToTake(fixed, flexibles) {
      let result = 0;
      if (flexibles.length >= 1) {
        flexibles.forEach(fa => {
          result += parseInt(fa.deduction_amount)
        });
      }
      result += parseInt(fixed);
      return result;
    },
    employeeFixedAllowances(id) {
      let _this = this, result = [], value = 0;
      if (_this.authFixedAllowances.length >= 1) {
        result = _this.authFixedAllowances.filter(fa => fa.employee_id == id);
        result.forEach(far => {
          value += parseInt(far.allowance_amount);
        })
      }
      return value;
    },
    realCheckin(time, today, msTime) {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}checkin`, {
          company_id: _this.authEmployeeDataForCompany.company_id,
          employee_id: _this.authEmployeeDataForCompany.id,
          check_in: time,
          punch_in_note: _this.formData.checkInNote,
          day: today,
          email: _this.authEmployeeDataForCompany.employee_email,
          ms_time: msTime,
          latitude: _this.geo_location_latitude,
          longitude: _this.geo_location_longitude,
          address: _this.geo_location_address
        })
        .then(function (response) {
          console.log("BELOW IS THE RESPONSE DATA FOR CHECK-IN");
          console.log(response.data);
          if (response.data.error) {
            Toast.fire({
              icon: "error",
              title: response.data.message
            });
            _this.refresh_checkin();
          } else {
            Toast.fire({
              icon: "success",
              title: "Check-in successful"
            });
            localStorage.setItem('last_day', today);
            localStorage.setItem(
              "msTime",
              new Date(response.data.attendant.ms_time).getTime()
            );
            _this.SET_CHECK_IN_TIME(
              new Date(response.data.attendant.ms_time).getTime()
            );
            localStorage.setItem("checkInStatus", 1);
            _this.GET_CHECK_IN_STATUS(1);
          }
          _this.formData.checkInNote = "";
        })
        .catch(function (error) {
          _this.refresh_checkin();
          let errors = error.response.data || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.check_in ? errors.check_in[0] : null
                || errors.day ? errors.day[0] : null
                  || errors.ms_time ? errors.ms_time[0] : null
            || error.response.data.message) : 'Could not check-in'
          });
          _this.checkInLoading = false;
          _this.checkInText = "Check-in";
        })
        .then(function () {
          _this.checkInLoading = false;
          _this.checkInText = "Check-in";
        })
    },
    realCheckout(time, today) {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.$swal
        .fire({
          title: "Are you sure you want to checkout?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Checkout`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}checkout`, {
                company_id: _this.authEmployeeDataForCompany.company_id,
                employee_id: _this.authEmployeeDataForCompany.id,
                check_out: time,
                punch_out_note: _this.formData.checkInNote,
                day: today,
                duration: _this.timeSpent
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: "Check-out successful"
                });
                localStorage.setItem("msTime", null);
                _this.SET_CHECK_IN_TIME(null);
                _this.timeSpent = "00:00:00";
                localStorage.setItem("checkInStatus", 0);
                _this.GET_CHECK_IN_STATUS(0);
                _this.formData.checkInNote = "";
              })
              .catch(function (error) {
                console.log(error.check_out);
                console.log(error.day);
                console.log(error.response.data.message);
                let errors = error.response.data.error || null;
                console.log(typeof errors);
                if (typeof errors == 'string') {
                  Toast.fire({
                    icon: "error",
                    title: error.response.data.message || 'Could not check-out'
                  });
                } else {
                  Toast.fire({
                    icon: "error",
                    title: errors ? (typeof errors === 'string' ? errors : null
                      || errors.check_out ? errors.check_out[0] : null
                        || errors.day ? errors.day[0] : null) : null
                        || error.response.data.message || 'Could not check-out'
                  });
                }
                console.log(error);
                _this.checkOutLoading = false;
                _this.checkOutText = "Check-out";

                // localStorage.setItem("msTime", null);
                // _this.SET_CHECK_IN_TIME(null);
                // _this.timeSpent = "00:00:00";
                // localStorage.setItem("checkInStatus", 0);
                // _this.GET_CHECK_IN_STATUS(0);
                // _this.formData.checkInNote = "";
              })
              .then(function () {
                _this.checkOutLoading = false;
                _this.checkOutText = "Check-out";
              });
          } else {
            _this.checkOutLoading = false;
            _this.checkOutText = "Check-out";
          }
        });
    },
    filtered_timesheets(id) {
      let _this = this;
      return _this.authCompanyAllTimesheets.filter(ts => {
        return ts.employee_id == id && ts.month == (new Date().getMonth() + 1) && ts.year == new Date().getFullYear()
      });
    },
    filtered_tasksheets(id) {
      let _this = this;
      return _this.authCompanyTaskSheets.filter(ts => {
        return ts.employee_id == id && ts.month == (new Date().getMonth() + 1) && ts.year == new Date().getFullYear()
      });
    },
    is_submitted(id) {
      let _this = this, all_submitted = [], filtered_timesheets = _this.filtered_timesheets(id);
      if (filtered_timesheets.length >= 1) {
        all_submitted = filtered_timesheets.filter(ft => {
          return ft.is_submitted == 1;
        });
        if (all_submitted.length == filtered_timesheets.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    tasksheet_is_submitted(id) {
      let _this = this, all_submitted = [], filtered_tasksheets = _this.filtered_tasksheets(id);
      if (filtered_tasksheets.length >= 1) {
        all_submitted = filtered_tasksheets.filter(ft => {
          return ft.is_submitted == 1;
        });
        if (all_submitted.length == filtered_tasksheets.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    check_is_today() {
      let _this = this;
      if (_this.checkedIn) {
        let is_today_interval = setInterval(() => {
          if (new Date(_this.checkInMs).toDateString() != new Date().toDateString() && _this.checkInMs != '') {
            let _this = this;
            if (!_this.checkOutLoading) {
              const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
              });
              _this.checkOutText = "";
              _this.checkOutLoading = true;
              axios
                .post(`${BASE_URL}checkout`, {
                  company_id: _this.authEmployeeDataForCompany.company_id,
                  employee_id: _this.authEmployeeDataForCompany.id,
                  check_out: null,
                  punch_out_note: 'Failed to checkout',
                  day: localStorage.getItem('last_day'),
                  duration: null
                })
                .then(function () {
                  Toast.fire({
                    icon: "success",
                    title: "Check-out successful"
                  });
                  localStorage.setItem("msTime", null);
                  _this.SET_CHECK_IN_TIME(null);
                  _this.timeSpent = "00:00:00";
                  localStorage.setItem("checkInStatus", 0);
                  _this.GET_CHECK_IN_STATUS(0);
                  _this.formData.checkInNote = "";
                  localStorage.setItem('last_day', null);
                  clearInterval(is_today_interval);
                })
                .catch(function () {
                  Toast.fire({
                    icon: "error",
                    title: "Could not check-out"
                  });
                  clearInterval(is_today_interval);
                })
                .then(function () {
                  _this.checkOutLoading = false;
                  _this.checkOutText = "Check-out";
                });
            }
          }
        }, 1000);
      }
    },
    get_profile_image(id) {
      let _this = this;
      if (_this.authCompanyEmployees.length) {
        let profile = _this.authCompanyEmployees.filter(person => {
          return person.id == id;
        });
        if (profile.length) {
          return profile[0].profile_image_url;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    track_leave_duration(start, end) {
      end += '  23:59:59';
      if (start != end) {
        let value = Math.floor(
          ((new Date().getTime() - new Date(start).getTime()) /
            (new Date(end).getTime() - new Date(start).getTime())) *
          100
        );
        if (value < 100) {
          return value
        }
        return 100;
      } else {
        let begin = start + " 00:00:00";
        let stop = end + " 23:59:59";
        let value = Math.floor(
          ((new Date().getTime() - new Date(begin).getTime()) /
            (new Date(stop).getTime() - new Date(begin).getTime())) *
          100
        );
        if (value < 100) {
          return value
        }
        return 100;
      }
    },
    get_dept_name(dept_id) {
      let _this = this;
      if (_this.authCompanyDepartments) {
        let dept = _this.authCompanyDepartments.filter(dept => {
          return dept.id == dept_id;
        });
        if (dept.length >= 1) {
          return dept[0].department_name;
        } else {
          return "No department";
        }
      }
      return "";
    },
    get_committee(committee_id) {
      let _this = this;
      if (_this.authCompanyCommittees) {
        let committee = _this.authCompanyCommittees.filter(committee => {
          return committee.id == committee_id;
        });
        if (committee.length >= 1) {
          return committee[0];
        } else {
          return null;
        }
      }
      return null;
    },
    approve_single_leave(leave) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        company_id: _this.authCompanyData.id,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to approve this leave request from " +
            leave.employee_name +
            "?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Approve`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}approveleave`, {
                ids: [
                  {
                    id: leave.id
                  }
                ]
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: "Leave approval successful"
                });
              })
              .catch(function (error) {
                Toast.fire({
                  icon: "error",
                  title: "Leave approval failed"
                });
                console.log(error);
              })
              .then(function () {
                _this.fetch_all_leave();
                _this.fetch_all_approved_leave();
              });
          }
        });
    },
    disapprove_single_leave(leave) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to disapprove this leave request from " +
            leave.employee_name +
            "?",
          showCancelButton: true,
          confirmButtonText: `Yes, Disapprove`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}disapproveleave`, {
                ids: [
                  {
                    id: leave.id
                  }
                ]
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: "Leave disapproval successful"
                });
              })
              .catch(function (error) {
                Toast.fire({
                  icon: "error",
                  title: "Leave disapproval failed"
                });
                console.log(error);
              })
              .then(function () {
                _this.fetch_all_leave();
                _this.fetch_all_approved_leave();
              });
          }
        });
    },
    refresh_page() {
      let _this = this;
      _this.refreshing_page = true;
      _this.fetch_candidates();
      _this.fetch_active_company_employees();
      _this.load_day_attendance();
      _this.timeSpentCalculator();
      setInterval(() => {
        _this.timeSpentCalculator();
      }, 1000);
      _this.refreshing_page = false;
    },
    load_day_attendance() {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchcheckingbyday`, {
          company_id: _this.authCompanyData.id,
          day: new Date().toISOString().substring(0, 10)
        })
        .then(function (response) {
          _this.day_attendance = response.data.attendance;
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .then(function () {
          //
        });
    },
    get_type_name(type_id) {
      let _this = this;
      if (_this.authCompanyAllLeaveType) {
        let type = _this.authCompanyAllLeaveType.filter(
          type => type.id == type_id
        );
        // console.log(type[0].leave_name);
        if (type[0]) {
          return type[0].leave_name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getEmployeeName(id) {
      let _this = this;
      if (_this.authCompanyEmployees) {
        return _this.authCompanyEmployees.forEach(person => {
          if (person.id == id)
            return person.employee_fristname + " " + person.employee_lastname;
        });
      } else {
        return "";
      }
    },
    getDaysArray(s, e) {
      let start = new Date(s), end = new Date(e), arr = [];
      for (
        let dt = new Date(start);
        dt <= end;
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    },
    load_announcement(announcement) {
      this.announcement_title = announcement.title;
      this.announcement_body = announcement.description;
      this.announcement_day = announcement.publish_date;
      this.announcement_time = announcement.publish_time;
      this.view_announcement_dialog = true;

      if (this.userType != 1) {
        let _this = this;
        axios
          .post(`${BASE_URL}setread`, {
            company_id: _this.authEmployeeDataForCompany.company_id,
            employee_id: _this.authEmployeeDataForCompany.id,
            employeeannouncement_id: announcement.id
          })
          .then(function () {
            _this.fetch_all_company_employee_announcements();
          })
          .catch(function (error) {
            console.log(error);
          })
          .then(function () {
            //
          });
      }
    },
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " years"
          : Math.floor(interval) + " year";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " months"
          : Math.floor(interval) + " month";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " days"
          : Math.floor(interval) + " day";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " hours"
          : Math.floor(interval) + " hour";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " minutes"
          : Math.floor(interval) + " minute";
      }
      return Math.floor(seconds) != 1
        ? Math.floor(seconds) + " seconds"
        : Math.floor(seconds) + " second";
    },
    timeFromNow(date) {
      var seconds = Math.floor((date - new Date()) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " years"
          : Math.floor(interval) + " year";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " months"
          : Math.floor(interval) + " month";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " days"
          : Math.floor(interval) + " day";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " hours"
          : Math.floor(interval) + " hour";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) != 1
          ? Math.floor(interval) + " minutes"
          : Math.floor(interval) + " minute";
      }
      return Math.floor(seconds) != 1
        ? Math.floor(seconds) + " seconds"
        : Math.floor(seconds) + " second";
    },
    delete_inline(id, type) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            type == 1
              ? "Are you sure you want to delete the candidate?"
              : "Are you sure you want to delete the employee?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}deleteemployee`, {
                ids: [
                  {
                    id
                  }
                ]
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title:
                    type == 1
                      ? "Candidate deleted successfully"
                      : "Employee deleted successfully"
                });
                _this.fetch_candidates();
                _this.fetch_active_company_employees();
              })
              .catch(function (error) {
                Toast.fire({
                  icon: "error",
                  title:
                    type == 1
                      ? "Could not delete the candidate"
                      : "Could not delete the employee"
                });
                console.log(error);
              })
              .then(function () {
                //
              });
          }
        });
    },
    timeSpentCalculator() {
      let _this = this;
      if (_this.checkInMs && _this.checkedIn) {
        let duration = new Date().getTime() - _this.checkInMs;
        let seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        // console.log(hours + ":" + minutes + ":" + seconds + "." + milliseconds)
        // return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
        _this.timeSpent = hours + ":" + minutes + ":" + seconds;
      } else {
        _this.timeSpent = "00:00:00";
      }
    },
    check_in() {
      let _this = this;
      // const Toast = _this.$swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 2000,
      //   timerProgressBar: true,
      //   didOpen: toast => {
      //     toast.addEventListener("mouseenter", _this.$swal.stopTimer);
      //     toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
      //   }
      // });

      if (_this.checkedIn) {
        _this.checkOutText = "";
        _this.checkOutLoading = true;


        let exDate = new Date();
        let offset = '+1', utc = exDate.getTime() + (exDate.getTimezoneOffset() * 60000);
        let mainDate = new Date(utc + (3600000 * offset));

        let d = new Date(mainDate);

        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        month = month <= 9 ? "0" + month.toString() : month;
        let day = d.getDate();
        day = day <= 9 ? "0" + day.toString() : day;

        let today = `${year.toString()}-${month.toString()}-${day.toString()}`;

        let hour = d.getHours();
        hour = hour <= 9 ? "0" + hour.toString() : hour;
        let minutes = d.getMinutes();
        minutes = minutes <= 9 ? "0" + minutes.toString() : minutes;
        let seconds = d.getSeconds();
        seconds = seconds <= 9 ? "0" + seconds.toString() : seconds;

        let time = `${today} ${hour.toString()}:${minutes.toString()}:${seconds.toString()}`;

        _this.realCheckout(time, today);

      } else {
        _this.checkInText = "";
        _this.checkInLoading = true;

        let exDate = new Date();
        let offset = '+1', utc = exDate.getTime() + (exDate.getTimezoneOffset() * 60000);
        let mainDate = new Date(utc + (3600000 * offset));

        let d = new Date(mainDate);

        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        month = month <= 9 ? "0" + month.toString() : month;
        let day = d.getDate();
        day = day <= 9 ? "0" + day.toString() : day;

        let today = `${year.toString()}-${month.toString()}-${day.toString()}`;

        let hour = d.getHours();
        hour = hour <= 9 ? "0" + hour.toString() : hour;
        let minutes = d.getMinutes();
        minutes = minutes <= 9 ? "0" + minutes.toString() : minutes;
        let seconds = d.getSeconds();
        seconds = seconds <= 9 ? "0" + seconds.toString() : seconds;

        let time = `${today} ${hour.toString()}:${minutes.toString()}:${seconds.toString()}`;

        let timeForMs = new Date(d.constructor().substring(0, 25))
        let msTime = timeForMs.getTime();
        msTime = new Date(msTime).toString();

        _this.locateAndCheckin(time, today, msTime);

      }
    }
  },
  mounted() {
    document.title = "Kylian ERP - Clientarea";
    let _this = this;
    _this.check_is_today();
    _this.refresh_company_data({
      user_id: _this.authUserData.id,
      company_id: _this.authCompanyData.id
    });
    if (_this.userType == 1) {
      _this.load_day_attendance();
    }
    _this.timeSpentCalculator();
    setInterval(() => {
      _this.timeSpentCalculator();
    }, 1000);

    setTimeout(() => {
      if (_this.$route.name == 'ClientArea') {
        window.location = window.location.href;
      }
    }, 1200000);
  }
};
</script>

<style>
@media (max-width: 991px) {
  .main-header {
    left: 0;
  }

  .main-content-wrap.sidenav-open .main-header {
    width: calc(100% - 0px);
  }
}

.ql-box {
  background: transparent;
  border: 2px solid #069;
  color: #069;
}

.ql-box:hover {
  background: #069;
  color: #fff;
  cursor: pointer;
}
</style>
<style scoped>
.ul-widget4__img img,
.ul-widget-s5__pic img {
  height: 2.3rem;
}

.ul-widget-s5__pic img {
  width: 2.5rem;
  border-radius: 100%;
  margin-right: 15px;
}

.ul-widget4__item {
  padding: 5px 0 !important;
}
</style>