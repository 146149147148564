<template>
    <div class="tab-pane fade" id="list-workflow-settings" role="tabpanel"
        aria-labelledby="list-workflow-settings-list">
        <div v-if="currentView == 'create'">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Create Workflow
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4 text-right">
                                <button @click="goToView('list')" style="float: right;" type="button"
                                    class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Workflow Title <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="newWorkflowForm.title" placeholder="Enter the workflow title"
                                        solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Service <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select v-model="newWorkflowForm.service_id" item-color="#069"
                                        item-text="service_name" item-value="id" :items="authWorkflowServices"
                                        placeholder="Select the service" solo class="mt-2" dense
                                        color="#069"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" offset-md="3" class="remove-hint">
                                    <button @click="
            creatingWorkflow
                ? null
                : createWorkflow()
            " style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ workflowCreationText }}
                                        <v-icon :style="!creatingWorkflow
            ? 'display: none;'
            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
            ">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else-if="currentView == 'list'">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Workflows
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="goToView('create')" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Create Workflow
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Name
                            </th>
                            <th scope="col" class="text-left">
                                Service
                            </th>
                            <th scope="col" class="text-left">
                                Status
                            </th>
                            <th scope="col" class="text-left">
                                Approvers
                            </th>
                            <th scope="col" style="width: 10px;">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="workflow in authCompanyWorkflows" :key="workflow.id">
                            <td class="text-left">
                                {{ workflow.title }}
                            </td>
                            <td class="text-left">
                                {{ workflow.service_name }}
                            </td>
                            <td class="text-left">
                                <label class="switch switch-secondary mr-3">
                                    <span v-if="workflow.status">Enabled</span>
                                    <span v-else>Disabled</span>
                                    <input @change="updateWorkflowStatus(workflow.id)"
                                        :disabled="updatingWorkflowStatus" type="checkbox" :checked="workflow.status">
                                    <span class="slider"></span>
                                </label>
                            </td>
                            <td class="text-left">
                                {{ workflow.approval_levels_count }}
                            </td>
                            <td class="pt-1">
                                <div class="ul-widget4__actions">
                                    <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="_dot _inline-dot bg-primary"></span>
                                        <span class="_dot _inline-dot bg-primary"></span>
                                        <span class="_dot _inline-dot bg-primary"></span>
                                    </button>
                                    <div class="dropdown-menu" x-placement="top-start"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                        <a class="dropdown-item" style="cursor: pointer;"
                                            @click="showApprovers(workflow)">
                                            <i class="fal fa-users" style="color: inherit !important;"></i> Approvers
                                        </a>
                                        <a class="dropdown-item" style="cursor: pointer;"
                                            @click="intiateWorkflowEdit(workflow)">
                                            <i class="fal fa-edit" style="color: inherit !important;"></i> Edit
                                        </a>
                                        <a class="dropdown-item" style="cursor: pointer;"
                                            @click="deleteWorkflow(workflow)">
                                            <i class="fal fa-trash"></i> Delete
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else-if="currentView == 'approvers'">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Approvers
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-6 py-4">
                                <button @click="goToView('list')" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    <i class="fas fa-arrow-left"></i>
                                </button>
                            </div>
                            <div class="col-lg-3 py-4">
                                <button @click="intiateApproverEntry()" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Add Approver
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div>
                <span class="text-danger font-weight-bold">
                    Approval levels start from Level one (1) up to a specified level signifying the first (1st) to the
                    final approver
                </span>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Approver
                            </th>
                            <th scope="col" class="text-left">
                                Level
                            </th>
                            <th scope="col" style="width: 10px;">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(approvalLevel, approvalLevelIndex) in currentWorkflowApprovalLevels"
                            :key="`approver-${approvalLevelIndex}`">
                            <td class="text-left">
                                {{ approvalLevel.approver.employee_fristname + ' ' + approvalLevel.approver.employee_lastname }}
                            </td>
                            <td class="text-left">
                                {{ approvalLevel.level }}
                            </td>
                            <td class="pt-1">
                                <div class="ul-widget4__actions">
                                    <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="_dot _inline-dot bg-primary"></span>
                                        <span class="_dot _inline-dot bg-primary"></span>
                                        <span class="_dot _inline-dot bg-primary"></span>
                                    </button>
                                    <div class="dropdown-menu" x-placement="top-start"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                        <a class="dropdown-item" style="cursor: pointer;"
                                            @click="deleteApprovalLevel(approvalLevel)">
                                            <i class="fal fa-trash"></i> Delete
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Add Approver ({{ currentWorkflowName }})
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4 text-right">
                                <button @click="goToView('list')" style="float: right;" type="button"
                                    class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Approver <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select v-model="newApprover.approver_id" item-color="#069" item-text="name"
                                        item-value="id" :items="allEmployees" placeholder="Select the approver" solo
                                        class="mt-2" dense color="#069"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Level <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select v-model="newApprover.level" item-color="#069" :items="approvalLevels"
                                        placeholder="Select level" solo class="mt-2" dense color="#069"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" offset-md="3" class="remove-hint">
                                    <button @click="
            addingApprovalLevel
                ? null
                : addApprovalLevel()
            " style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ approvalLevelAdditionText }}
                                        <v-icon :style="!addingApprovalLevel
            ? 'display: none;'
            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
            ">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <v-dialog v-model="editWorkflowDialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Workflow</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Workflow Title <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="workflowEditForm.title"
                                            placeholder="Enter the workflow title" solo class="mt-2"
                                            dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Service <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-select v-model="workflowEditForm.service_id" item-color="#069"
                                            item-text="service_name" item-value="id" :items="authWorkflowServices"
                                            placeholder="Select the service" solo class="mt-2" dense
                                            color="#069"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button @click="updatingWorkflow ? null : updateWorkflow()" style="width: 100%;"
                                            type="button" class="btn btn-secondary m-1 ripple">
                                            {{ workflowUpdateText }}
                                            <v-icon :style="!updatingWorkflow
            ? 'display: none;'
            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
            ">
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="editWorkflowDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
    props: {},
    data() {
        return {
            currentView: 'list',
            currentWorkflowId: '',
            currentWorkflowName: '',
            currentWorkflowApprovalLevels: [],
            newWorkflowForm: {
                title: "",
                service_id: "",
                employee_id: "",
                company_id: ""
            },
            workflowEditForm: {
                id: null,
                title: "",
                service_id: "",
                employee_id: "",
                company_id: ""
            },
            newApprover: {
                workflowsetting_id: null,
                service_id: null,
                approver_id: 0,
                level: 1,
                employee_id: null,
                company_id: null
            },
            workflowCreationText: "Submit",
            creatingWorkflow: false,
            approvalLevelAdditionText: "Submit",
            addingApprovalLevel: false,
            workflowUpdateText: "Update",
            updatingWorkflow: false,
            editWorkflowDialog: false,
            updatingWorkflowStatus: false,
            maxApprovalLevel: 10
        }
    },
    computed: {
        ...mapState([
            'userToken'
        ]),
        ...mapGetters([
            'authCompanyData',
            'validEmployeeId',
            'authCompanyEmployees',
            'authCompanyWorkflows',
            'authWorkflowServices'
        ]),
        approvalLevels() {
            const _this = this, levels = [];
            for (let level = 1; level <= _this.maxApprovalLevel; level++) {
                levels.splice(
                    levels.length,
                    0,
                    level
                );
            };
            return levels;
        },
        allEmployees() {
            const _this = this;
            return _this.authCompanyEmployees.map(EMPLOYEE => {
                return {
                    name: `${EMPLOYEE.employee_fristname} ${EMPLOYEE.employee_lastname}`,
                    id: EMPLOYEE.id
                }
            });
        }
    },
    methods: {
        ...mapActions([
            'fetch_company_workflows',
            'fetch_company_approval_levels',
            'fetch_workflow_services'
        ]),
        goToView(view) {
            this.currentView = view;
        },
        showApprovers(workflow) {
            const _this = this;
            _this.newApprover.workflowsetting_id = workflow.id;
            _this.newApprover.service_id = workflow.service_id;
            _this.newApprover.approver_id = 0;
            _this.newApprover.level = 1;
            _this.newApprover.employee_id = _this.validEmployeeId;
            _this.newApprover.company_id = _this.authCompanyData.id;
            _this.currentWorkflowId = workflow.id;
            _this.currentWorkflowName = workflow.title;
            _this.currentWorkflowApprovalLevels = workflow.approval_levels;
            _this.goToView('approvers');
        },
        intiateApproverEntry() {
            this.goToView('addApprover');
        },
        intiateWorkflowEdit(workflow) {
            this.workflowEditForm.id = workflow.id;
            this.workflowEditForm.title = workflow.title;
            this.workflowEditForm.service_id = workflow.service_id;
            // CONTINUE
            this.editWorkflowDialog = true;
            // CONTINUE
        },
        deleteApprovalLevel(approvalLevel) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this approval level?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.post(`${BASE_URL}deleteapprovallevel`, {
                            ids: [
                                {
                                    id: approvalLevel.id,
                                },
                            ],
                            company_id: _this.authCompanyData.id,
                            employee_id: _this.validEmployeeId
                        }, {
                            headers: {
                                Authorization: `Bearer ${_this.userToken}`
                            }
                        })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Approval level deleted successfully",
                                });
                                _this.fetch_company_workflows().then(() => {
                                    let currentWorkflowObject = _this.authCompanyWorkflows.filter(WORKFLOW => WORKFLOW.id == _this.currentWorkflowId);
                                    if (currentWorkflowObject.length >= 1) {
                                        [currentWorkflowObject] = currentWorkflowObject;
                                        _this.currentWorkflowApprovalLevels = currentWorkflowObject.approval_levels
                                    }
                                });
                            })
                            .catch(function (error) {
                                console.log(error)
                                Toast.fire({
                                    icon: "error",
                                    title: "Approval level couldn't be deleted",
                                });
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        deleteWorkflow(workflow) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this workflow [" + workflow.title + "]?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.post(`${BASE_URL}deleteworkflowsetting`, {
                            ids: [
                                {
                                    id: workflow.id,
                                },
                            ],
                            company_id: _this.authCompanyData.id,
                            employee_id: _this.validEmployeeId
                        }, {
                            headers: {
                                Authorization: `Bearer ${_this.userToken}`
                            }
                        })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Workflow [" + workflow.title + "] deleted successfully",
                                });
                                _this.fetch_company_workflows();
                            })
                            .catch(function (error) {
                                console.log(error)
                                Toast.fire({
                                    icon: "error",
                                    title: "Workflow [" + workflow.title + "] couldn't be deleted",
                                });
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        createWorkflow() {
            const _this = this;
            _this.workflowCreationText = "";
            _this.creatingWorkflow = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios.post(`${BASE_URL}createworkflowsetting`, {
                company_id: _this.authCompanyData.id,
                title: _this.newWorkflowForm.title,
                service_id: _this.newWorkflowForm.service_id,
                employee_id: _this.validEmployeeId,
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: _this.newWorkflowForm.title + " was added successfully",
                    });
                    _this.newWorkflowForm.title = "";
                    _this.fetch_company_workflows();
                    _this.goToView('list');
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.title
                                    ? errors.title[0]
                                    : null
                            : null || error.response.data.error || error.response.data.message || "The workflow could not be added",
                    });
                    _this.fetch_company_workflows();
                })
                .then(function () {
                    _this.creatingWorkflow = false;
                    _this.workflowCreationText = "Submit";
                });
        },
        updateWorkflow() {
            const _this = this;
            _this.workflowUpdateText = "";
            _this.updatingWorkflow = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}updateworkflowsetting`, {
                company_id: _this.authCompanyData.id,
                workflowsetting_id: _this.workflowEditForm.id,
                title: _this.workflowEditForm.title,
                service_id: _this.workflowEditForm.service_id,
                employee_id: _this.validEmployeeId
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The workflow has been updated successfully",
                    });
                    _this.workflowEditForm.id = null;
                    _this.workflowEditForm.title = "";
                    _this.fetch_company_workflows();
                    _this.editWorkflowDialog = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.title
                                    ? errors.title[0]
                                    : null
                            : null || error.response.data.error || error.response.data.message || "The workflow could not be updated",
                    });
                })
                .then(function () {
                    _this.updatingWorkflow = false;
                    _this.workflowUpdateText = "Update";
                });
        },
        updateWorkflowStatus(workflowId) {
            const _this = this;
            _this.updatingWorkflowStatus = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}updateworkflowstatus`, {
                workflow_id: workflowId,
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The workflow status has been updated successfully",
                    });
                    _this.fetch_company_workflows();
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.workflow_id
                                    ? errors.workflow_id[0]
                                    : null
                            : null || error.response.data.error || error.response.data.message || "The workflow status could not be updated",
                    });
                })
                .then(function () {
                    _this.updatingWorkflowStatus = false;
                });
        },
        addApprovalLevel() {
            const _this = this;
            _this.approvalLevelAdditionText = "";
            _this.addingApprovalLevel = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}createapprovallevel`, _this.newApprover, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The approval level has been added successfully",
                    });
                    _this.fetch_company_workflows();
                    _this.goToView('list');
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.level
                                    ? errors.level[0]
                                    : null
                            : null || error.response.data.error || error.response.data.message || "The approval level could not be added",
                    });
                })
                .then(function () {
                    _this.addingApprovalLevel = false;
                    _this.approvalLevelAdditionText = "Submit";
                });
        }
    },
    mounted() {
        const _this = this;
        _this.fetch_workflow_services();
        _this.fetch_company_approval_levels();
        _this.fetch_company_workflows();
    }
}
</script>

<style></style>